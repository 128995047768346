import React, { useState, useEffect } from "react";
import {BaseButton, ButtonContainer, DumbLink, FlexColumn, FlexRow, SecondaryText} from "./BaseStyledComponents";
import styled, {css} from 'styled-components/macro';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAuth} from "../contexts/AuthContext";
import AuthErrors from "./AuthErrors";
import { Link, useHistory } from "react-router-dom";
import {InputField} from "./SignUp";


export const Login = ({ handleCloseModal, setAuthMode=null, isModal}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState("");
  const [errors, setErrors] = useState([]);

  const { login, loginWithGoogle } = useAuth();
  const history = useHistory();

  const handleClose = () => {
    setEmail("");
    setPassword("");
    handleCloseModal(false)
  };

  const handleProviderLogin = async (loginCall) => {
    await loginCall();
    isModal ? handleClose() : history.push("/")
  };

  const handleSubmit = async (email, password) => {
    try {
      setErrors([]);
      setLoading(true);
      let error = await login(email, password);
      error && setErrors([error]);
      isModal && !error ? handleClose() : history.push("/")
    } catch {
      setErrors(["Failed to login"]);
    }
    setLoading(false)
  };

  return (
    <FlexColumn>
      {isModal &&
        <CancelContainer>
          <TimesIcon icon={["fa", "times"]} onClick={handleClose}/>
        </CancelContainer>
      }
      <AuthContentContainer>
        <AlternateLoginOptions>
          <WideButtonContainer>
            <WideButton secondary onClick={() => handleProviderLogin(loginWithGoogle)}>Log in with Google</WideButton>
          </WideButtonContainer>
        </AlternateLoginOptions>
        <Divider>
          <DividerLine/>
          <DividerText>OR</DividerText>
          <DividerLine/>
        </Divider>
        <EmailLoginContainer>
          {errors.length > 0 && <AuthErrors errors={errors}/>}
          <InputFieldContainer>
            <FieldText>Email</FieldText>
            <span onChange={(e) => setEmail(e.target.value)}>
              <InputField placeholder={"Email address"}/>
            </span>
          </InputFieldContainer>
          <InputFieldContainer>
            <FieldText>Password</FieldText>
            <span onChange={(e) => setPassword(e.target.value)}>
              <InputField type="password" placeholder="•••••••••"/>
            </span>
          </InputFieldContainer>
          <WideButtonContainer>
            <WideButton disabled={loading} onClick={() => handleSubmit(email, password)}>Log in</WideButton>
          </WideButtonContainer>
        </EmailLoginContainer>
        <FlexColumn>
          <SecondaryText>
            {isModal
              ? <DumbLink onClick={() => setAuthMode("reset-password")}>Forgot password?</DumbLink>
              : <Link to={"/reset-password"}>Forgot password?</Link>
            }
          </SecondaryText>
          <SecondaryText>
            New to Nexos Health?&nbsp;
            {isModal
              ? <DumbLink onClick={() => setAuthMode("signup")}>Sign up</DumbLink>
              : <Link to={"/signup"}>Sign up</Link>
            }
          </SecondaryText>
        </FlexColumn>
      </AuthContentContainer>
    </FlexColumn>
  )
};

const AuthContentContainer = styled(FlexColumn)`
  padding: 20px;
`;

const TimesIcon = styled(FontAwesomeIcon)`
  align-self: center;
  &:hover {
    cursor: pointer
  }
`;

const CancelContainer = styled(FlexRow)`
  justify-content: flex-end;
`;

const AlternateLoginOptions = styled(FlexColumn)`
  padding: 0 0 10px 0;
`;

const EmailLoginContainer = styled(FlexColumn)`
  padding-bottom: 5px;
`;

const WideButton = styled(BaseButton)`
  width: 100%;
`;

const WideButtonContainer = styled.div`
  width: 100%;
  align-self: center;
`;

const InputFieldContainer = styled(FlexColumn)`
  justify-content: space-between;
  padding: 0 0 20px 0;
`;

const Divider = styled(FlexRow)`
  justify-content: space-between;
  padding: 0 0 20px 0;
`;

const DividerLine = styled.hr`
  flex: 1 0;
  height: 1px;
  border: none;
  border-bottom: 1px solid rgba(64,87,109,.07);
  margin: 7px 0;
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
`;

const DividerText= styled.span`
  margin: 0 8px;
  font-size: 12px;
  font-weight: 600;
  color: rgba(47,64,80,.25);
`;

const FieldText = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: black;
`;


