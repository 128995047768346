import React, { useState } from "react";
import {
  BaseButton,
  BoldedText,
  DumbLink,
  FlexColumn,
  FlexRow,
  SecondaryText, Text
} from "./BaseStyledComponents";
import styled, {css} from 'styled-components/macro';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAuth} from "../contexts/AuthContext";
import AuthErrors from "./AuthErrors";
import { Link, useHistory } from "react-router-dom";
import {InputField} from "./SignUp";


export const ResetPassword = ({ handleCloseModal, setAuthMode=null, isModal=false }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState("");
  const [errors, setErrors] = useState([]);

  const { resetPassword } = useAuth();
  const history = useHistory();

  const handleClose = () => {
    setEmail("");
    handleCloseModal(false)
  };

  const handleSubmit = async (email) => {
    try {
      setErrors([]);
      setLoading(true);
      await resetPassword(email);
      isModal ? setAuthMode("reset-password-confirmed") : history.push("/reset-password-confirmed")
    } catch {
      setErrors(["Failed to reset password"]);
    }
    setLoading(false)
  };

  return (
    <FlexColumn>
      {isModal &&
        <CancelContainer>
          <TimesIcon icon={["fa", "times"]} onClick={handleClose}/>
        </CancelContainer>
      }
      <AuthContentContainer>
        <HeaderTextContainer>
          <HeaderText>
            Forgotten your password?
          </HeaderText>
          <Text>
            No worries, just enter your email in below and we'll send you a message to help you reset your password
          </Text>
        </HeaderTextContainer>
        <EmailLoginContainer>
          {errors.length > 0 && <AuthErrors errors={errors}/>}
          <InputFieldContainer>
            <FieldText>Email</FieldText>
            <span onChange={(e) => setEmail(e.target.value)}>
              <InputField placeholder={"Email address"}/>
            </span>
          </InputFieldContainer>
          <WideButtonContainer>
            <WideButton disabled={loading} onClick={() => handleSubmit(email)}>Reset Password</WideButton>
          </WideButtonContainer>
        </EmailLoginContainer>
        <FlexColumn>
          <SecondaryText>
            {isModal
              ? <DumbLink onClick={() => setAuthMode("login")}>Return to log in</DumbLink>
              : <Link to={"/login"}>Return to log in</Link>
            }
          </SecondaryText>
        </FlexColumn>
      </AuthContentContainer>
    </FlexColumn>
  )
};

const AuthContentContainer = styled(FlexColumn)`
  padding: 20px;
`;

const HeaderTextContainer = styled(FlexColumn)`
  padding: 0 0 20px 0;
`;

const HeaderText = styled(BoldedText)`
  font-weight: 800;
  font-size: 20px;
  color: black;
`;

const TimesIcon = styled(FontAwesomeIcon)`
  align-self: center;
  &:hover {
    cursor: pointer
  }
`;

const CancelContainer = styled(FlexRow)`
  justify-content: flex-end;
`;

const EmailLoginContainer = styled(FlexColumn)`
  padding-bottom: 5px;
`;

const WideButton = styled(BaseButton)`
  width: 100%;
`;

const WideButtonContainer = styled.div`
  width: 100%;
  align-self: center;
`;

const InputFieldContainer = styled(FlexColumn)`
  justify-content: space-between;
  padding: 0 0 20px 0;
`;

const FieldText = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: black;
`;


