import React, { createContext, useContext, useState, useEffect }from "react";
import firebase from "firebase/app";

import { auth } from "../utils/firebase";
import {deregisterUser, registerUser} from "../utils/amplitude";
import {sleep} from "../utils/helpers";
import {useDispatch, useSelector} from "react-redux";
import {clearAccount, clearProfile, fetchProfile} from "../redux/actions/professional";

const AuthContext = createContext();
export let accessToken;

export const useAuth = () => {
  return useContext(AuthContext)
};

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch()

  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState();

  // Initialize the FirebaseUI Widget using Firebase.

  const signup = (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password).then(() => {
      return null
    }).catch(error => {
      return error.message
    })
  };

  const login = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password).then(() => {
      return null
    }).catch(error => {
      return error.message
    })
  };

  const loginWithGoogle = async () => {
    let provider = await new firebase.auth.GoogleAuthProvider();
    return auth.signInWithPopup(provider)
  };

  const logout = () => {
    dispatch(clearAccount())
    return auth.signOut()
  };

  const resetPassword = (email) => {
    return auth.sendPasswordResetEmail(email)
  };

  const updateEmail = (email) => {
    return user.updateEmail(email)
  };

  const updatePassword = (password) => {
    return user.updatePassword(password)
  };

  const getAccessToken = async (currentUser) => {
    return await currentUser.getIdToken()
  };

  const updateAccessToken = (currentUser) => {
    if (currentUser) {
      getAccessToken(currentUser).then(token => {
        if (token) {
          accessToken = token;
        }
      })
    }
  }

  const handleAuthentication = (user) => {
    if (user) {
      getAccessToken(user).then(token => {
        setUser(user);
        accessToken = token;
        if (user && (Date.now() - Date.parse(user.metadata.creationTime))/1000 < 10) {
          setLoading(true)
          sleep(5000).then(() => {
            setIsAuthenticated(true);
            setLoading(false)
          })
        } else {
          setIsAuthenticated(true)
          setLoading(false);
        }
        dispatch(fetchProfile())
        registerUser(user)
      });
    } else {
      setUser(null);
      accessToken = null;
      setIsAuthenticated(false);
      dispatch(clearProfile())
      deregisterUser(user)
    }
    setLoading(false);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      handleAuthentication(user)
    });
    return unsubscribe;
  }, []);

  // Keep refreshing the token every 3 minutes to ensure the accessToken doesn't become stale.
  setInterval(() => updateAccessToken(user), 180000)


  const value = {
    user,
    isAuthenticated,
    loading,
    signup,
    login,
    loginWithGoogle,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    updateAccessToken,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
};