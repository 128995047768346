import React, {useEffect, useState} from "react";
import {Link, NavLink as RouterNavLink, useHistory} from "react-router-dom";
import { AiOutlineClose } from 'react-icons/ai'
import { IconContext } from 'react-icons';
import styled, { css } from 'styled-components/macro';

import { SidebarData } from "./SidebarData";
import {BaseButton, FancyButton, FlexColumn, FlexRow} from "./BaseStyledComponents";
import {DARK_COLOR_VARIATION, DARK_TEXT_COLOR, PRIMARY_COLOR, SIDEBAR_WIDTH, TOP_BAR_HEIGHT} from "../utils/constants"
import {
  Button,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  UncontrolledDropdown
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {clickEvent, deregisterUser} from "../utils/amplitude";
import {CreateGroupForm} from "./CreateGroupForm";
import Modal from "react-modal";
import {AuthenticationForm} from "./AuthenticationForm";
import {accessToken, useAuth} from "../contexts/AuthContext";
import Loading from "./Loading";
import {StyledRemoveScroll} from "../containers/search/SearchResults";
import {useSelector} from "react-redux";
import {HashLink} from "react-router-hash-link";


const NavBar = ({toggleSidebar, sidebar }) => {

  const history = useHistory()
  const profile = useSelector(state => state.professionals.profile);


  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [authMode, setAuthMode] = useState("login");

  const { user, isAuthenticated, logout, loading } = useAuth();

  const authModalCustomStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      height                : '95%',
      maxHeight             : authMode === "signup"
                              ? '470px'
                              : (authMode === "login"
                                ? '405px'
                                : '340px'),
      width                 : '100%',
      maxWidth              : '350px',
      transform             : 'translate(-50%, -50%)',
      padding               : '10px 2px',
    },
    overlay : {
      zIndex                : 600,
      backgroundColor       : "rgb(27 20 20 / 75%)",
    },
  };

  const handleLogInButton = () => {
    setAuthModalOpen(true);
    setAuthMode("login");
    clickEvent("login", {"location": "navbar"})
  };

  const handleSignUpButton = () => {
    setAuthModalOpen(true);
    setAuthMode("signup");
    clickEvent("signup", {"location": "navbar"})
  };

  if (!isAuthenticated && user && accessToken) {
    deregisterUser();
  }

  return (
    <>
    <IconContext.Provider value={{color: '#fff'}}>
      <TopNavBar>
        {/*<MenuBarsContainer>*/}
        {/*  <MenuIcon icon={["fas", "bars"]} onClick={toggleSidebar}/>*/}
        {/*</MenuBarsContainer>*/}
        <LogoContainer>
          <HashLink to="/#">
            <LogoImage src={"https://storage.googleapis.com/nexos-branding/icons/NexosLogoLongText.svg"} alt={"Long Logo"}/>
          </HashLink>
        </LogoContainer>
        <SimpleLogoContainer>
          <HashLink to="/#">
            <LogoImage src={"https://storage.googleapis.com/nexos-branding/icons/NexosLogoShortText.svg"} alt={"Short Logo"}/>
          </HashLink>
        </SimpleLogoContainer>
        <NavBarMenuItems>
          <LinkItem to="/#for-patients">Patients</LinkItem>
          <LinkItem to="/#for-gps">GPs</LinkItem>
          <LinkItem to="/#for-health-professionals">Health Professionals</LinkItem>
          <LinkItem to="/#about">About</LinkItem>
          <LinkItem to="/#contact-us">Contact</LinkItem>
        </NavBarMenuItems>
        <ActionsContainer>
          <StyledNav className="d-md-block" navbar>
            {isAuthenticated
              ? <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret id="profileDropDown">
                  {user && user.photoURL
                    ? <img
                      src={user.photoURL}
                      alt="Profile"
                      className="nav-user-profile rounded-circle"
                      width="35"
                    />
                    :<ProfileIcon icon={["fas", "user-circle"]}/>
                  }
                </DropdownToggle>
                <DropdownMenu right style={{position: "absolute"}}>
                  <DropdownItem header>{user && user.displayName}</DropdownItem>
                  {user && profile.professionalUid &&
                    <>
                      <StyledDropdownItem
                        id="qsProfileBtn"
                        onClick={() => history.push(`/profile/${profile.professionalUid}`)}
                      >
                        Edit profile
                      </StyledDropdownItem>
                      <StyledDropdownItem id="qsProfileBtn" onClick={() => history.push("/invitations")}>
                        Colleagues
                      </StyledDropdownItem>
                    </>
                    }
                  <Divider/>
                  <StyledDropdownItem id="qsLogoutBtn" onClick={() => logout()}>
                    Log out
                  </StyledDropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              : !loading && <NavButtonsContainer>
                <StyledNavItem>
                  <FancyButtonNavBar secondary onClick={handleLogInButton}>Log In</FancyButtonNavBar>
                </StyledNavItem>
                <StyledNavItem>
                  <FancyButtonNavBar onClick={handleSignUpButton}>Sign Up</FancyButtonNavBar>
                </StyledNavItem>
              </NavButtonsContainer>
            }
          </StyledNav>
        </ActionsContainer>
      </TopNavBar>
      <NavMenu active={sidebar}>
        <SideBarMenuItems>
          {/*<NavToggle>*/}
            {/*<Link to="#">*/}
              {/*<AiOutlineClose onClick={toggleSidebar}/>*/}
            {/*</Link>*/}
          {/*</NavToggle>*/}
          {SidebarData.map((item, index) => {
            return (
              <NavText key={index}>
                <NavTextLink to={item.path}>
                  {item.icon}
                  <TitleSpan>{item.title}</TitleSpan>
                </NavTextLink>
              </NavText>
            )
          })}
        </SideBarMenuItems>
      </NavMenu>
      <Modal
        isOpen={authModalOpen}
        onRequestClose={() => setAuthModalOpen(false)}
        style={authModalCustomStyles}
        contentLabel="Example Modal"
      >
        <StyledRemoveScroll>
          <AuthenticationForm setAuthMode={setAuthMode} authMode={authMode} handleCloseModal={() => setAuthModalOpen(false)}/>
        </StyledRemoveScroll>
      </Modal>
    </IconContext.Provider>
    </>
  );


    {/*<div className="nav-container">*/}
      {/*<Navbar color="light" light expand="md">*/}
        {/*<Container>*/}
          {/*<NavbarBrand className="logo" />*/}
          {/*<NavbarToggler onClick={toggle} />*/}
          {/*<Collapse isOpen={isOpen} navbar>*/}
            {/*<StyledNav className="mr-auto" navbar>*/}
              {/*<NavItem>*/}
                {/*<NavLink*/}
                  {/*tag={RouterNavLink}*/}
                  {/*to="/"*/}
                  {/*exact*/}
                  {/*activeClassName="router-link-exact-active"*/}
                {/*>*/}
                  {/*Search*/}
                {/*</NavLink>*/}
              {/*</NavItem>*/}
            {/*</StyledNav>*/}
            {/*<StyledNav className="d-none d-md-block" navbar>*/}
              {/*{!isAuthenticated && (*/}
                {/*<NavItem>*/}
                  {/*<Button*/}
                    {/*id="qsLoginBtn"*/}
                    {/*color="primary"*/}
                    {/*className="btn-margin"*/}
                    {/*onClick={() => loginWithPopup({})}*/}
                  {/*>*/}
                    {/*Sign In*/}
                  {/*</Button>*/}
                {/*</NavItem>*/}
              {/*)}*/}
              {/*{isAuthenticated && (*/}
                {/*<UncontrolledDropdown nav inNavbar>*/}
                  {/*<DropdownToggle nav caret id="profileDropDown">*/}
                    {/*<img*/}
                      {/*src={user.picture}*/}
                      {/*alt="Profile"*/}
                      {/*className="nav-user-profile rounded-circle"*/}
                      {/*width="50"*/}
                    {/*/>*/}
                  {/*</DropdownToggle>*/}
                  {/*<DropdownMenu>*/}
                    {/*<DropdownItem header>{user.name}</DropdownItem>*/}
                    {/*<DropdownItem*/}
                      {/*tag={RouterNavLink}*/}
                      {/*to="/profile"*/}
                      {/*className="dropdown-profile"*/}
                      {/*activeClassName="router-link-exact-active"*/}
                    {/*>*/}
                      {/*<FontAwesomeIcon icon="user" className="mr-3" /> Profile*/}
                    {/*</DropdownItem>*/}
                    {/*<DropdownItem*/}
                      {/*id="qsLogoutBtn"*/}
                      {/*onClick={() => logoutWithRedirect()}*/}
                    {/*>*/}
                      {/*<FontAwesomeIcon icon="power-off" className="mr-3" /> Log out*/}
                    {/*</DropdownItem>*/}
                  {/*</DropdownMenu>*/}
                {/*</UncontrolledDropdown>*/}
              {/*)}*/}
            {/*</StyledNav>*/}
            {/*{!isAuthenticated && (*/}
              {/*<StyledNav className="d-md-none" navbar>*/}
                {/*<NavItem>*/}
                  {/*<Button*/}
                    {/*id="qsLoginBtn"*/}
                    {/*color="primary"*/}
                    {/*block*/}
                    {/*onClick={() => loginWithPopup({})}*/}
                  {/*>*/}
                    {/*Sign In*/}
                  {/*</Button>*/}
                {/*</NavItem>*/}
              {/*</StyledNav>*/}
            {/*)}*/}
            {/*{isAuthenticated && (*/}
              {/*<Nav*/}
                {/*className="d-md-none justify-content-between"*/}
                {/*navbar*/}
                {/*style={{ minHeight: 170 }}*/}
              {/*>*/}
                {/*<NavItem>*/}
                  {/*<span className="user-info">*/}
                    {/*<img*/}
                      {/*src={user.picture}*/}
                      {/*alt="Profile"*/}
                      {/*className="nav-user-profile d-inline-block rounded-circle mr-3"*/}
                      {/*width="50"*/}
                    {/*/>*/}
                    {/*<h6 className="d-inline-block">{user.name}</h6>*/}
                  {/*</span>*/}
                {/*</NavItem>*/}
                {/*<NavItem>*/}
                  {/*<FontAwesomeIcon icon="user" className="mr-3" />*/}
                  {/*<RouterNavLink*/}
                    {/*to="/profile"*/}
                    {/*activeClassName="router-link-exact-active"*/}
                  {/*>*/}
                    {/*Profile*/}
                  {/*</RouterNavLink>*/}
                {/*</NavItem>*/}
                {/*<NavItem>*/}
                  {/*<FontAwesomeIcon icon="power-off" className="mr-3" />*/}
                  {/*<RouterNavLink*/}
                    {/*to="#"*/}
                    {/*id="qsLogoutBtn"*/}
                    {/*onClick={() => logoutWithRedirect()}*/}
                  {/*>*/}
                    {/*Log out*/}
                  {/*</RouterNavLink>*/}
                {/*</NavItem>*/}
              {/*</StyledNav>*/}
            {/*)}*/}
          {/*</Collapse>*/}
        {/*</Container>*/}
      {/*</Navbar>*/}
    {/*</div>*/}
};

export default NavBar;

const TopNavBar = styled(FlexRow)`
  background-color: #fefefe;
  height: ${TOP_BAR_HEIGHT};
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  padding: 0 20px;
  z-index: 2;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
`;

const StyledNavItem = styled(NavItem)`
  // @media (max-width: 800px) {
  //   ${props => props.hideMobile && css`
  //     display: none;
  //   `}
  //   ${props => props.noMargin && css`
  //     margin-right: -20px;
  //   `}
  // }
`;

const MenuBarsLink = styled(HashLink)`
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: ${DARK_COLOR_VARIATION};
  @media (min-width: 800px) {
    display: none;
  }
`;

const FancyButtonNavBar = styled(FancyButton)`
  padding: calc(.575rem - 1px) calc(1.25rem - 1px);
  ${props => props.secondary && css`
    border: none;    
  `}
`;

const NavMenu = styled.nav`
  background-color: #f8f9fa;
  width: ${SIDEBAR_WIDTH};
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: ${TOP_BAR_HEIGHT};
  left: -100%;
  transition: 850ms;
  z-index: 1;
  box-shadow: 2px 5px 3px 0 rgba(0,0,0,0.16);
  ${props => props.active && css`
    left: 0;
    transition: 350ms;
  `}
`;

const NavText = styled.li`
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 16px;
  list-style: none;
  height: 50px;
`;

const NavTextLink = styled(Link)`
  color: #394963;
  font-size: 16px;
  font-weight: 600;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  &:hover {
    background-color: #ebebeb;
    text-decoration: none;
  }
`;

const SideBarMenuItems = styled.ul`
  width: 100%;
  padding-inline-start: 0;
  padding-top: 25px;
`;

const NavToggle = styled.li`
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
`;

const TitleSpan = styled.span`
  margin-left: 16px;
`;

export const LogoImage = styled.img`
  height: 35px;
  @media (min-width: 800px) {
    content: url(https://storage.googleapis.com/nexos-branding/icons/NexosLogoLongText.svg);
  }
`;

const LogoContainer = styled.div`
  padding-left: 10px;
  @media (max-width: 800px) {
    display: none;
  }
`;

const SimpleLogoContainer = styled.div`
  @media (min-width: 800px) {
    display: none;
  }
`;

const MenuBarsContainer = styled.div`
  @media (min-width: 800px) {
    display: none;
  }
`;

const NavBarMenuItems = styled(FlexRow)`
  justify-content: space-between;
  flex-grow: 1;
  max-width: 500px;
  padding: 0 25px;
  @media (max-width: 800px) {
    display: none;
  }
`;

const LinkItem = styled(HashLink)`
  flex-grow: 0;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.76;
  letter-spacing: normal;
  text-align: right;
  color: ${DARK_TEXT_COLOR};
  padding-right: 14px;
  white-space: nowrap;
  @media (max-width: 800px) {
    display: none;
  }
`;

const NavButtonsContainer = styled(FlexRow)`
  padding-top: 0.5em;
`;

const ActionsContainer = styled.div`
  height: 100%;
`;

const StyledNav = styled(Nav)`
  height: 100%;
`;

const Divider = styled.div`
  height: 1px;
  margin: 0.25rem 0;
  padding: 0;
  background-color: #dbdbdb;
`;

export const StyledDropdownItem = styled(DropdownItem)`
  font-size: 14px;
`;

const ProfileIcon = styled(FontAwesomeIcon)`
  vertical-align: middle;
  color: darkorange;
  font-size: 36px;
`;

const MenuIcon = styled(FontAwesomeIcon)`
  vertical-align: middle;
  color: ${PRIMARY_COLOR};
  font-size: 30px;
  &:hover {
    cursor: pointer;
  }
`;