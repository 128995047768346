// Utility functions for the amplitude-js package
const amplitude = require('amplitude-js');

export const registerUser = (user) => {
  amplitude.getInstance().setUserId(user.email);
};

export const deregisterUser = () => {
  amplitude.getInstance().setUserId(null);
};

export const viewPageEvent = () => {
  amplitude.getInstance().logEvent(
    "viewed page",
    {"url": window.location.pathname}
  );
};

export const clickEvent = (element, clickOptions={}) => {
  amplitude.getInstance().logEvent(
    "clicked " + element,
    {...{"url": window.location.pathname}, ...clickOptions}
  );
};

export const submitEvent = (action, clickOptions={}) => {
  amplitude.getInstance().logEvent(
    "submitted " + action,
    {...{"url": window.location.pathname}, ...clickOptions}
  );
};

export const selectEvent = (element, selectOptions={}) => {
  amplitude.getInstance().logEvent(
    "selected " + element,
    {...{"url": window.location.pathname}, ...selectOptions}
  );
};