import React, {useState, useEffect} from "react";
import styled, { css } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  BoldedText,
  FlexColumn,
  FlexRow,
  OverflowText,
  ParagraphText,
  GridParent, ButtonContainer, BaseButton, TextArea
} from "../BaseStyledComponents";
import {useAuth} from "../../contexts/AuthContext";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-modal";
import {StyledRemoveScroll} from "../../containers/search/SearchResults";
import {EditProfileInfo} from "./EditProfileInfo";
import {
  acceptConnection,
  createConnection,
  deleteConnection,
  editProfessionalNotes,
  fetchConnections,
  removeMedicalLocation,
  submitCorrection
} from "../../redux/actions/professional";
import {clickEvent, submitEvent} from "../../utils/amplitude";
import {PopUpPrompt} from "../PopUpPrompt";
import verifiedAccount from "../../assets/verified-account.png";
import {EllipsisDropdown} from "../../containers/colleagues/Network";


const editInfoStyles = {
  content : {
    position              : 'relative',
    top                   : '50%',
    left                  : '50%',
    height                : '95%',
    maxHeight             : '650px',
    width                 : '95%',
    maxWidth              : '550px',
    transform             : 'translate(-50%, -50%)',
    padding               : "0",
  },
  overlay : {
    zIndex                : 400,
    backgroundColor       : "rgb(27 20 20 / 75%)",
  },
};

const promptStyles = {
  content : {
    top                   : '40%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    height                : 'auto',
    width                 : '95%',
    maxWidth              : '400px',
    transform             : 'translate(-50%, -50%)',
    padding               : "10px 5px",
  },
  overlay : {
    zIndex                : 600,
    backgroundColor       : "rgb(27 20 20 / 75%)",
  },
};


const ConnectionAction = ({connectionType, connection, handleConnection}) => {
  if (connectionType === "RECEIVED") {
    return(
      <ConnectionStatusContainer>
        <ConnectionButton secondary onClick={() => handleConnection(false, connection)}>Ignore</ConnectionButton>
        <ConnectionButton onClick={() => handleConnection(true, connection)}>Accept</ConnectionButton>
      </ConnectionStatusContainer>
    )
  } else if (connectionType === "SENT") {
    return(
      <ConnectionStatusContainer>
        <ConnectionButton disabled>Pending</ConnectionButton>
      </ConnectionStatusContainer>
    )
  } else if (connectionType === "NOT_CONNECTED"){
    return (
      <ConnectionStatusContainer>
        <ConnectionButton onClick={() => handleConnection(true, null)}>Add Colleague</ConnectionButton>
      </ConnectionStatusContainer>
    )
  } else {
    return null
  }
}

const ProfileHeader = ({professional, connection, connectionType, handleConnection, handleCorrectionModal, isAuthenticated, isEditable}) => {
  let clinics = professional.clinics

  let professionTypesString = professional.professions.map((profession) => {
    return profession.professionType
  }).join(", ")

  let clinicsList = [...new Set(clinics.map(clinic => {
    return clinic.name
  }))]

  let clinicsSummaryText = ""
  let locationSummaryText = [...new Set(clinics.map(clinic => {
    return `${clinic.location.suburb}, ${clinic.location.state}`
  }))].join(" | ")

  if (clinicsList.length > 2) {
    clinicsSummaryText = clinicsList.slice(0, 2).join(", ") + `, and ${clinics.length - 2} more`
  } else {
    clinicsSummaryText = clinicsList.join(", ")
  }


  return(
    <SummaryContainer>
      <HeroImage>
        <ProfileIconCircle>
          {professional.photoUrl ? null : <ProfileIcon icon={["fas", "user-circle"]}/>}
        </ProfileIconCircle>
      </HeroImage>
      <ExtraOptionsContainer>
        {connection && isAuthenticated &&
          <EllipsisDropdown
            iconType="ellipsis-h"
            handleRemoveConnection={() => handleConnection(false, connection)}
          />
        }
      </ExtraOptionsContainer>
      <ProfessionalHeaderContainer removeTopPadding>
        <ProfessionalInfoContainer>
          <SubmitCorrectionContainer>
            <SubmitCorrectionText
              hide={!(isAuthenticated && !isEditable)}
              onClick={() => {
                handleCorrectionModal(true);
                clickEvent(
                  "submit-correction",
                  {"professional": `${professional.firstName} ${professional.lastName}`}
                )}}
            >
              Submit Correction
            </SubmitCorrectionText>
          </SubmitCorrectionContainer>
          <NameContainer>
            <ProfessionalName>{professional.title} {professional.firstName} {professional.lastName}</ProfessionalName>
            {professional.user && <VerifiedIcon src={verifiedAccount} alt="verified-account"/>}
          </NameContainer>
          <ProfessionType>{professionTypesString}</ProfessionType>
          {professional.subSpecialties && <SubSpecialties>{professional.subSpecialties}</SubSpecialties>}
          <Divider/>
          <LocationSummaryContainer>
            <LocationIcon icon={["fas", "map-marker-alt"]}/>
            <LocationSummary>{locationSummaryText}</LocationSummary>
          </LocationSummaryContainer>
          <LocationsList>{clinicsSummaryText}</LocationsList>
        </ProfessionalInfoContainer>
        {isAuthenticated &&
          <ConnectionAction
            connectionType={connectionType}
            connection={connection}
            handleConnection={(accept) => handleConnection(accept, connection)}
            isAuthenticated={isAuthenticated}
          />
        }
      </ProfessionalHeaderContainer>
    </SummaryContainer>
  )
}

const ConnectionButton = styled(BaseButton)`
  ${props => props.disabled && css`
    opacity: 50%;
  `};
  ${props => props.secondary && css`
    margin-right: 5px;
  `}
`;

const ConnectionStatusContainer = styled(FlexRow)`
  justify-content: center;
  padding: 10px;
`

const ExtraOptionsContainer = styled(FlexRow)`
  justify-content: flex-end;
  padding-right: 10px;
`

export const Location = ({medicalLocation, isEditable=false, handleRemoveMedicalLocation=null}) => {
  return(
    <LocationContainer key={medicalLocation.name}>
      <LocationImageContainer>
        <img src="https://storage.googleapis.com/nexos-branding/icons/BuildingCircleIcon.svg"/>
      </LocationImageContainer>
      <LocationInfoContainer>
        <LocationHeader>
          <LocationName>{medicalLocation.name}</LocationName>
          {isEditable && <EditText onClick={() => handleRemoveMedicalLocation(medicalLocation.id)}>Remove</EditText>}
        </LocationHeader>
        {/*{clinic.description && <SubSectionText>{clinic.description}</SubSectionText>}*/}
        <SubSectionText>{medicalLocation.location.streetNumber} {medicalLocation.location.streetName}, {medicalLocation.location.suburb}, {medicalLocation.location.state}, {medicalLocation.location.postcode}</SubSectionText>
        <div>
          <ShortSeparator/>
        </div>
        <LocationContactsContainer>
          <ContactFieldContainer>
            <LocationContactIcon icon={["fas", "phone"]}/>
            <LocationContact>{medicalLocation.phone || "-"}</LocationContact>
          </ContactFieldContainer>
          <ContactFieldContainer>
            <LocationContactIcon icon={["fas", "fax"]}/>
            <LocationContact>{medicalLocation.fax || "-"}</LocationContact>
          </ContactFieldContainer>
          <ContactFieldContainer>
            <LocationContactIcon icon={["fas", "globe"]}/>
            {medicalLocation.website
              ? <LocationWebsite href={medicalLocation.website} target="_blank" rel="noopener noreferrer" link={medicalLocation.website}>Website</LocationWebsite>
              : <LocationContact>-</LocationContact>
            }
          </ContactFieldContainer>
        </LocationContactsContainer>
      </LocationInfoContainer>
    </LocationContainer>
  )
}

export const ProfileView = ({professional, setSignInModalOpen=null, accountProfile=false}) => {

  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const profile = useSelector(state => state.professionals.profile);
  const connections = useSelector(state => state.professionals.connections.all);

  const [notesExpanded, setNotesExpanded] = useState(false);
  const [editInfo, setEditInfo] = useState([]);
  const [notes, setNotes] = useState(null);
  const [editNotes, setEditNotes] = useState(null);
  const [correctionText, setCorrectionText] = useState("");
  const [deleteEditsWarningModalOpen, setDeleteEditsWarningModalOpen] = useState(null);
  const [correctionModal, setCorrectionModal] = useState(false);
  const [connection, setConnection] = useState(null);

  const isEditable = (profile.professionalUid === professional.uid) && accountProfile

  if (professional && professional.uid === profile.professionalUid) {
    accountProfile = true
  }

  const connectionMapping = (connection) => {
    if (!connection && !accountProfile) {
      return "NOT_CONNECTED"
    } else if (accountProfile) {
      return null
    } else if (connection.connectionDatetime) {
      return "CONNECTED"
    } else if (connection.professional.uid === professional.uid) {
      return "RECEIVED"
    } else if (connection.connectedProfessional.uid === professional.uid) {
      return "SENT"
    } else {
      return null
    }
  }

  const getConnection = (connections) => {
    if (accountProfile) {
      return null
    }
    if (Object.keys(connections).length === 0) {
      return null
    } else {
      let connection = Object.values(connections).filter((connection) => {
        let professionalUid = connection.professional && connection.professional.uid
        let connectedProfessionalUid = connection.connectedProfessional && connection.connectedProfessional.uid
        return (professional.uid && [professionalUid, connectedProfessionalUid].indexOf(professional.uid) >= 0)
      })
      if (connection.length === 1) {
        return connection[0]
      } else {
        return null
      }
    }
  }

  useEffect(() => {
    if (Object.keys(connections)) {
      setConnection(getConnection(connections))
    }
  }, [connections])

  useEffect(() => {
    if (Object.keys(connections).length === 0) {
      dispatch(fetchConnections())
    }
    setNotes(professional.userNotes);
  }, [])

  const handleCancelNotes = () => {
    setEditNotes(false);
    setNotes(professional.userNotes);
  };

  const handleRemoveMedicalLocation = (locationId) => {
    dispatch(removeMedicalLocation(professional.uid, locationId))
  }

  const handleSaveNotes = () => {
    submitEvent(
      "professional-note",
      {
        "professional": professional.firstName + " " + professional.lastName,
        "profession": professional.professionType
      }
    );
    dispatch(editProfessionalNotes(professional.uid, notes));
    setEditNotes(false);
  };

  const handleSubmitCorrection = () => {
    dispatch(submitCorrection(professional.uid, professional.firstName, professional.lastName, correctionText));
    submitEvent(
      "correction",
      {
        "professional": professional.firstName + " " + professional.lastName,
        "correctionText": correctionText,
      }
    );
    setCorrectionModal(false);
  };

  const handleEditInfoClose = (unsavedNotes) => {
    if (unsavedNotes) {
      setDeleteEditsWarningModalOpen(true)
    } else {
      setEditInfo([])
    }
  }

  const handleConnection = (accept, connection) => {
    if (connection) {
      accept
        ? dispatch(acceptConnection(connection.id))
        : dispatch(deleteConnection(connection.id))
    } else {
      dispatch(createConnection(professional.uid))
    }
  }

  return (
    <ProfileContainer isEditable={isEditable}>
      <ProfileHeader
        professional={professional}
        connection={connection}
        connectionType={connectionMapping(connection)}
        handleConnection={handleConnection}
        handleCorrectionModal={setCorrectionModal}
        isAuthenticated={isAuthenticated}
        isEditable={isEditable}
      />
      <CurrentProfessionalInfo>
        {!isEditable &&
          <PaddingContainer>
            <InfoSubSection>
              <NotesTopBar>
                <HeaderText>Your Notes</HeaderText>
                <EditText
                  onClick={isAuthenticated ? () => setEditNotes(true) : () => setSignInModalOpen(true)}
                  hidden={editNotes}>
                  Edit
                </EditText>
              </NotesTopBar>
              {editNotes
                ? <NotesTextAreaContainer>
                  <NotesTextArea onChange={(e) => setNotes(e.target.value)}>
                    {notes}
                  </NotesTextArea>
                  <ButtonContainers>
                    <ButtonContainer>
                      <BaseButton secondary onClick={handleCancelNotes}>Cancel</BaseButton>
                    </ButtonContainer>
                    <BaseButton onClick={handleSaveNotes}>Save</BaseButton>
                  </ButtonContainers>
                </NotesTextAreaContainer>
                : <>
                  <DisplayedNotes expanded={notesExpanded} empty={!notes || notes.length === 0}>
                    {notes || "Make your own, personal notes on this professional"}
                  </DisplayedNotes>
                </>
              }
            </InfoSubSection>
          </PaddingContainer>
        }
        <PaddingContainer>
          <InfoSubSection>
            <HeaderContainer>
              <HeaderText>Areas of Interest</HeaderText>
              <EditText
                onClick={() => setEditInfo(["interestAreas", "additionalInfo"])}
                hidden={!isEditable}>
                Edit
              </EditText>
            </HeaderContainer>
            <SubSectionText>{professional.description || "-"}</SubSectionText>
          </InfoSubSection>
          <InfoSubSection>
            <HeaderText>Additional Information</HeaderText>
            <SubSectionText>{professional.additionalInformation || "-"}</SubSectionText>
          </InfoSubSection>
        </PaddingContainer>
        <PaddingContainer>
          <InfoSubSection>
            <FieldContainer>
              <HeaderContainer>
                <SmallHeaderText>WAIT TIME</SmallHeaderText>
                <EditText
                  onClick={() => setEditInfo(["waitTimeText", "feesText", "bulkBillingText"])}
                  hidden={!isEditable}
                  noPadding>
                  Edit
                </EditText>
              </HeaderContainer>
              <SubSectionText>{professional.waitTimesText || "-"}</SubSectionText>
            </FieldContainer>
            <FieldContainer>
              <SmallHeaderText>FEES</SmallHeaderText>
              <SubSectionText>{professional.feesText || "-"}</SubSectionText>
            </FieldContainer>
            <FieldContainer>
              <SmallHeaderText>BULK BILLING</SmallHeaderText>
              <SubSectionText>{professional.bulkBillingText || "-"}</SubSectionText>
            </FieldContainer>
          </InfoSubSection>
        </PaddingContainer>
        <PaddingContainer noBorder>
          <HeaderContainer>
            <HeaderText>Locations</HeaderText>
            <EditText
              onClick={() => setEditInfo(["location"])}
              hidden={!isEditable}>
              Add Location
            </EditText>
          </HeaderContainer>
          <ThinSeparator/>
          <LocationsContainer>
            {professional.clinics.map((location) => {
              return (
                <Location
                  medicalLocation={location}
                  isEditable={isEditable}
                  handleRemoveMedicalLocation={handleRemoveMedicalLocation}/>
              )
            })}
          </LocationsContainer>
        </PaddingContainer>
      </CurrentProfessionalInfo>
      <Modal
        isOpen={editInfo.length !== 0}
        style={editInfoStyles}
      >
        <StyledRemoveScroll>
            <EditProfileInfo
              user={professional}
              fieldsList={editInfo}
              handleModal={handleEditInfoClose}
            />
        </StyledRemoveScroll>
      </Modal>
      <Modal
        isOpen={deleteEditsWarningModalOpen}
        style={promptStyles}
      >
        <StyledRemoveScroll>
          <PopUpPrompt
            message="Are you sure you wish to cancel unsaved changes?"
            closeText="Cancel"
            submitText="I'm Sure"
            handleClose={() => setDeleteEditsWarningModalOpen(false)}
            handleSubmit={() => {setDeleteEditsWarningModalOpen(false); setEditInfo([])}}
          />
        </StyledRemoveScroll>
      </Modal>
      <Modal
        isOpen={correctionModal}
        style={promptStyles}
      >
        <StyledRemoveScroll>
          <PopUpPrompt
            message="Please let us know which information you believe is incorrect or missing."
            text={correctionText}
            closeText="Cancel"
            submitText="Submit"
            handleClose={() => setCorrectionModal(false)}
            handleSubmit={handleSubmitCorrection}
            handleText={setCorrectionText}
          />
        </StyledRemoveScroll>
      </Modal>
    </ProfileContainer>
  )
};


const SummaryContainer = styled(FlexColumn)`
  box-shadow: 0 7px 0 0 #e8e5df;
;`

const ProfessionalHeaderContainer = styled(FlexColumn)`
  width: 100%;
  background-color: #fff;
  padding: 16px;
  ${props => props.removeTopPadding && css`
    padding: 5px 16px 16px;
  `}
;`

const SubmitCorrectionContainer = styled(FlexRow)`
  justify-content: flex-end;
`;

const SubmitCorrectionText = styled(BoldedText)`
  background-color: #fba630;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
  margin-right: -10px;
  &:hover {
    cursor: pointer;
  }
  ${props => props.hide && css`
    visibility: hidden;
  `}
`;

const NameContainer = styled(FlexRow)`
  justify-content: center;
`;

const VerifiedIcon = styled.img`
  align-self: center;
  margin: 0 0 0 2px;
  width: 25px;
`;

const HeroImage = styled(FlexRow)`
  width: 100%;
  height: 115px;
  justify-content: center;
  background-color: cornflowerblue;
;`

const ProfessionalInfoContainer = styled(FlexColumn)`
  
;`

const ProfileIconCircle = styled.div`
  position: relative;
  bottom: -20px;
  font-size: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: white
`;

const ProfileIcon = styled(FontAwesomeIcon)`
  margin: 3px;
  align-self: center;
  font-size: 2.7em;
  color: cornflowerblue;
`;

const ProfessionalName = styled(ParagraphText)`
  color: #000000;
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  letter-spacing: -0.56px;
  line-height: 22px;
`;

const ProfessionType = styled(OverflowText)`
  color: #191919;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  line-height: normal;
  margin-bottom: 10px;
  letter-spacing: -0.3px;
`;

const SubSpecialties = styled(OverflowText)`
  margin: 0 0 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.48px;
  text-align: center;
  color: #666666;
`;

const ProfileContainer = styled(FlexColumn)`
  height: auto;
  justify-content: flex-start;
  overflow: auto;
  max-width: 800px;
  background-color: white;
  margin: ${props => (props.isEditable ? "0 0 15px" : "0 0 50px")};
  box-shadow: 0 7px 7px 0 #e8e5df;
  
  @media (min-width: 400px) {
    margin: ${props => (props.isEditable && "15px 0 15px")};
    border-radius: 5px;
  }
;`

export const Divider = styled.li`
  width: 90%;
  height: 1px;
  margin: 0.25rem 0;
  background-color: #dbdbdb;
  align-self: center;
  list-style: none;
`;

const LocationSummaryContainer = styled(FlexRow)`
  padding-top: 5px;
  justify-content: center;
;`


export const LocationIcon = styled(FontAwesomeIcon)`
  font-size: 15px;
  align-self: center;
  margin-right: 8px;
  color: #0f6fff;
`;

const LocationSummary = styled(OverflowText)`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.48px;
  text-align: center;
  color: #666666;
;`

const LocationsList = styled(ParagraphText)`
  width: 60%;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.3px;
  align-self: center;
  text-align: center;
  justify-content: center;
  color: #191919;
`;

const CurrentProfessionalInfo = styled(FlexColumn)`
  overflow: auto;
  padding: 10px 0 0;
  ${props => props.modal && css`
    margin-bottom: 60px;
    margin-top: 75px;
  `}
;`

const PaddingContainer = styled(FlexColumn)`
  padding: 16px 16px 2px;
  margin: 0 0 7px;
  box-shadow: 0 7px 0 0 #e8e5df;
  ${props => props.noBorder && css`
    box-shadow: none;
  `}
;`

const EditText = styled(BoldedText)`
  align-self: flex-start;
  padding: ${props => props.noPadding ? "0 0 0 10px" : "3px 0 0 10px"};
  font-size: 13px;
  line-height: normal;
  color: #0066f9;
  ${props => props.hidden && css`
    display: none;
  `}
  &:hover {
    cursor: pointer;
  }
`;

const NotesTopBar = styled(FlexRow)`
  justify-content: space-between;
  color: #191919;
  &:hover {
    cursor: pointer
  }
`;

const NotesTextArea = styled(TextArea)`
  min-height: 150px;
  overflow: scroll;
`;

const NotesTextAreaContainer = styled(FlexColumn)`
  padding-top: 3px;
`;

const DisplayedNotes = styled(ParagraphText)`
  font-size: 14px;
  overflow: hidden;
  transition: height 500ms;
  color: #666666;
  ${props => props.expanded && css`
    height: auto;
    min-height: 75px;
  `}
`;

const InfoSubSection = styled(FlexColumn)`
  padding: 0 0 14px;
;`


const SubSectionText = styled(ParagraphText)`
  font-size: 14px;
  color: #666666;
  line-height: normal;
`;

export const HeaderText = styled(BoldedText)`
  align-self: flex-start;
  padding-right: 5px;
  font-size: 14px;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.17px;
  font-stretch: normal;
  font-style: normal;
  color: #191919;
`;

const HeaderContainer = styled(FlexRow)`
  justify-content: space-between;
`;

const SmallHeaderText = styled(HeaderText)`
  font-size: 10px;
  font-weight: 900;
  padding-bottom: 4px;
`;

const MediumHeaderText = styled(HeaderText)`
  font-size: 18px;
`;

const LargeHeaderText = styled(HeaderText)`
  font-size: 20px;
`;

const FieldContainer = styled(FlexColumn)`
  font-size: 16px;
  padding: 5px 0;
`;

const Separator = styled.hr`
  background-color: #efedea;
  border: none;
  width: 100%;
  margin: 5px 0;
  height: 0.75em;
`;

export const ThinSeparator = styled(Separator)`
  height: 0.1em;
`;

const ShortSeparator = styled(ThinSeparator)`
  width: 30%;
`;

const ButtonContainers = styled(FlexRow)`
  padding-left: 10px;
  padding-top: 10px;
  justify-content: flex-end;
`;

// Clinic Section
const LocationContainer = styled(FlexRow)`
  justify-content: flex-start;
  overflow-x: auto;
  padding: 0.25em 0 0.25em 0;
`;

const LocationInfoContainer = styled(FlexColumn)`
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid rgb(204, 204C, 204);
`;

const LocationImageContainer = styled(FlexColumn)`
  justify-content: space-between;
  padding: 2px 12px 0 0;
`;


const LocationHeader = styled(FlexRow)`
  justify-content: space-between;
`;

const LocationName = styled.div`
  font-weight: 900;
  font-size: 14px;
  color: #191919;
  ${props => props.link && css`
     &:hover {
      cursor: default;
     } 
  `}
`;

const LocationContactsContainer = styled(FlexColumn)`
  font-size: 12px;
  padding-bottom: 0.5em;
  &:hover {
    cursor: pointer;
  }
`;

const ContactFieldContainer = styled(FlexRow)`
  font-size: 12px;
  padding-right: 0.8em;
`;

const LocationContact = styled(ParagraphText)`
  font-size: 12px;
  color: #666666;
  white-space: nowrap;
`;

const LocationWebsite = styled.a`
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  ${props => props.link && css`
     &:hover {
      cursor: pointer;
    } 
  `}
`;

const LocationContactIcon = styled(FontAwesomeIcon)`
  align-self: center;
  font-size: 12px;
  color: #4a90e2;
  margin-right: 0.3em;
  &:hover {
    cursor: pointer;
  }
`;

const LocationsContainer = styled(GridParent)`
  row-gap: 5px;
  column-gap: 30px;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
