import React, {useState} from "react";
import {BaseButton, ButtonContainer, FlexColumn, FlexRow, TextArea} from "./BaseStyledComponents";
import styled from "styled-components/macro";

export const PopUpPrompt = ({message, text, closeText, submitText, handleClose, handleSubmit, handleText=null}) => {
  return (
    <PromptContainer>
      <MessageContainer>
        <div>{message}</div>
      </MessageContainer>
      {handleText && (
        <TextAreaContainer>
          <TextArea onChange={(e) => handleText(e.target.value)}>{text}</TextArea>
        </TextAreaContainer>
      )}
      <Actions>
        <ButtonContainer><BaseButton secondary onClick={handleClose}>{closeText}</BaseButton></ButtonContainer>
        <ButtonContainer><BaseButton onClick={handleSubmit}>{submitText}</BaseButton></ButtonContainer>
      </Actions>
    </PromptContainer>
  )
};

const PromptContainer = styled(FlexColumn)`
  width: 100%;
  text-align: left;
`;

const MessageContainer = styled(FlexColumn)`
  padding: 10px;
  font-size: 14px;
  line-height: 16px;
`;

const TextAreaContainer = styled(FlexColumn)`
  padding: 5px 10px 0;
  font-size: 16px;
`;

const Actions = styled(FlexRow)`
  padding: 10px;
  justify-content: flex-end;
`;