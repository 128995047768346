import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import styled, {css} from 'styled-components/macro';

import {
  BoldedText, FlexColumn,
  FlexRow, OverflowText,
} from "../../components/BaseStyledComponents";
import {useAuth} from "../../contexts/AuthContext";
import {
  acceptConnection, deleteConnection,
  favourProfessional,
  fetchConnections,
  fetchFavourites,
  fetchProfessional,
  unfavourProfessional
} from "../../redux/actions/professional";
import Loading from "../../components/Loading";
import verifiedAccount from "../../assets/verified-account.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {currentChoiceStyles, CurrentProfessionalWrapper, StyledRemoveScroll} from "../search/SearchResults";
import {CurrentProfessionalContent} from "../../components/CurrentProfessionalContent";
import Modal from "react-modal";
import {submitEvent} from "../../utils/amplitude";
import {RightArrowIcon, StarIcon, StyledDropdownToggle, StyledUncontrolledDropdown} from "../../components/ProfessionalListItem";
import {DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {StyledDropdownItem} from "../../components/NavBar";


export const EllipsisDropdown = ({ handleRemoveConnection, iconType="ellipsis-v" }) => {

  return (
    <StyledUncontrolledDropdown nav inNavbar>
      <StyledDropdownToggle nav id="profileDropDown">
        <OptionsIcon icon={["fas", iconType]}/>
      </StyledDropdownToggle>
      <DropdownMenu right style={{position: "absolute"}}>
        <StyledDropdownItem text id="qsProfileBtn">
          <FlexRow onClick={handleRemoveConnection}>
            <OptionsText>Remove Connection</OptionsText>
          </FlexRow>
        </StyledDropdownItem>
      </DropdownMenu>
    </StyledUncontrolledDropdown>
  )
}

const OptionsText = styled(OverflowText)`
  &:hover {
    cursor: pointer;
  }
`;


const ConnectionListItem = ({professional, connection, handleInvitation, handleSelectProfessional, pending=false}) => {
  let professionTypes = professional.professions.map((profession) => {
    return profession.professionType
  })
  let professionTypesText = professionTypes.join(", ")
  return (
    <ConnectionContainer>
      <FlexRow>
        <ProfileIcon icon={["fas", "user-circle"]}/>
        <ProfessionalHeaderText>
          <ProfessionalNameContainer onClick={() => handleSelectProfessional(professional.uid)}>
            <ProfessionalName>
              {professional.firstName} {professional.lastName}
            </ProfessionalName>
            {professional.user && <VerifiedIcon src={verifiedAccount} alt="verified-account"/>}
            <RightArrowIcon icon={["fas", "angle-right"]}/>
          </ProfessionalNameContainer>
          {/*<ProfessionType>{professional.professionType}</ProfessionType>*/}
          <ProfessionType>{professionTypesText}</ProfessionType>
        </ProfessionalHeaderText>
      </FlexRow>
      {pending
        ? <InviteButtonContainer disabled>Pending</InviteButtonContainer>
        : connection.connectionDatetime
          ? <InviteButtonContainer>
            <EllipsisDropdown handleRemoveConnection={() => handleInvitation(false, connection)}/>
          </InviteButtonContainer>
          : <FlexRow>
            <InviteButtonContainer>
              <InviteButton icon={["far", "times-circle"]} onClick={() => handleInvitation(false, connection)}/>
            </InviteButtonContainer>
            <InviteButtonContainer>
              <InviteButton icon={["fas", "check-circle"]} onClick={() => handleInvitation(true, connection)}/>
            </InviteButtonContainer>
          </FlexRow>
      }
    </ConnectionContainer>
  )
}


const ConnectionContainer = styled.li`
  display: flex;
  flex-direction: row;
  padding: 10px 0 8px;
  margin: 0 20px;
  justify-content: space-between;
  border-bottom: 1px solid #cccac6;
  :last-child {
    border-bottom: none;
  }
`

const InviteButtonContainer = styled.div`
  padding: 7px;
  font-size: 14px;
  align-self: center;
  ${props => props.disabled && css`
    
  `}
`;

const InviteButton = styled(FontAwesomeIcon)`
  font-size: 26px;
  color: #cccac6;
  &:hover {
    cursor: pointer;
  }
`;

export const OptionsIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: #cccac6;
  &:hover {
    cursor: pointer;
  }
`;


const ConnectionsContainerHeading = (
    {title, count, hasMore=false, handleSeeAll=null, handleGoBack=null}
  ) => {

  return (
    <HeadingContainer>
      {handleGoBack && <LeftArrowIcon icon={["fas", "angle-left"]} onClick={handleGoBack}/>}
      <LeftHeading>
        <HeadingTitle>{title}</HeadingTitle>
        <CountText>{count}</CountText>
      </LeftHeading>
      <SeeAllText hide={!hasMore || handleGoBack} onClick={handleSeeAll}>See all</SeeAllText>
    </HeadingContainer>
  )
}

const LeftHeading = styled(FlexRow)`
  padding: 5px 0;
`;

const HeadingContainer = styled(FlexRow)`
  justify-content: space-between;
  padding: 5px 25px;
`;

const HeadingTitle = styled(BoldedText)`
  font-weight: 900;
  color: #191919;
  padding-right: 5px;
  font-size: 16px;
`;

const CountText = styled(BoldedText)`
  color: #0066f9;
  align-self: center;
  font-size: 16px;
`;

const SeeAllText = styled(BoldedText)`
  align-self: center;
  padding: ${props => props.noPadding ? "0 0 0 10px" : "3px 0 0 10px"};
  font-size: 13px;
  line-height: normal;
  color: #0066f9;
  ${props => props.hide && css`
    visibility: hidden;
  `}
  &:hover {
    cursor: pointer;
  }
`;

const formatConnections = (uid, connections) => {
  let received = [];
  let sent = [];
  let connected = [];

  for (let connection of Object.values(connections)) {
    if (!connection.connectionDatetime) {
      if (connection.connectedProfessional.uid === uid) {
        received.push(connection)
      } else {
        sent.push(connection)
      }
    } else {
      connected.push(connection)
    }
  }

  return [received, sent, connected]
}


export const Network = ({allColleagues}) => {

  const { loading, isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();

  const professionals = useSelector(state => state.professionals);
  const profile = useSelector(state => state.professionals.profile);
  const groups = useSelector(state => state.professionals.groups);

  const [currentProfessional, setCurrentProfessional] = useState(null)
  const [currentProfessionalUid, setCurrentProfessionalUid] = useState(null)

  const favouriteGroup = Object.entries(groups).filter(([uid, group]) => {
    return group.name === "Favourites"
  });

  let favouritesUid, favourites;
  if (favouriteGroup.length === 1) {
    [favouritesUid, favourites] = favouriteGroup[0]
  }

  useEffect(() => {
    if (groups.length === 0 && isAuthenticated) {
      dispatch(fetchFavourites())
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (profile.professionalUid) {
      dispatch(fetchConnections())
    }
  }, [profile])

  useEffect(() => {
    if (professionals && Object.keys(professionals.professionals).indexOf(currentProfessionalUid) >= 0) {
      setCurrentProfessional(professionals.professionals[currentProfessionalUid])
    }
  }, [professionals.professionals])


  if (loading || professionals.loadingProfile || professionals.connections.loadingConnections) {
    return <Loading fullScreen={false}/>
  }

  // if (!isAuthenticated) {
  //   history.push("/")
  // }

  const handleInvitation = (accept, connection) => {
    if (accept) {
      dispatch(acceptConnection(connection.id))
    } else {
      dispatch(deleteConnection(connection.id))
    }
  }

  const handleSeeAll = () => {
    history.push("/colleagues")
  }


  const handleSelectProfessional = (uid) => {
    dispatch(fetchProfessional(uid))
    setCurrentProfessionalUid(uid)
  }

  const handleGoBack = () => {
    history.push("/invitations")
  }

  const handleFavourProfessional = (professional) => {
    let submitOptions = {
      "professional": professional.firstName + " " + professional.lastName,
      "profession": professional.professionType
    };
    if (favourites && favourites.professionalsUids.indexOf(professional.uid) >= 0) {
      submitEvent("unfavour-professional", submitOptions);
      dispatch(unfavourProfessional(professional.uid, favouritesUid))
    } else {
      submitEvent("favour-professional", submitOptions);
      dispatch(favourProfessional(professional.uid, favouritesUid))
    }
  };

  const retrieveOtherProfessional = (connection) => {
    if (connection.professional.uid === profile.professionalUid) {
      return connection.connectedProfessional
    } else {
      return connection.professional
    }
  }


  let [received, sent, connected] = formatConnections(profile.professionalUid, professionals.connections.all || [])

  return (
    <InvitationsPageContainer>
      {allColleagues
        ? <ConnectionsContainer>
          <ConnectionsSectionContainer>
            <ConnectionsContainerHeading
              count={connected.length} title="Colleagues" handleGoBack={handleGoBack} />
          </ConnectionsSectionContainer>
          <ConnectionsSectionContainer>
            <ConnectionsList>
              {connected.map((connection) => {
                let professional = retrieveOtherProfessional(connection)
                return (
                  <>
                    <ConnectionListItem
                      professional={professional}
                      connection={connection}
                      handleInvitation={handleInvitation}
                      handleSelectProfessional={handleSelectProfessional}
                    />
                  </>
                )
              })}
            </ConnectionsList>
          </ConnectionsSectionContainer>
        </ConnectionsContainer>
        : <ConnectionsContainer>
          <ConnectionsSectionContainer>
            <ConnectionsContainerHeading
              count={connected.length} hasMore={true} title="Colleagues" handleSeeAll={handleSeeAll}/>
          </ConnectionsSectionContainer>
          <ConnectionsSectionContainer>
            <ConnectionsHeadingWrapper>
              <ConnectionsContainerHeading count={received.length} title="Invitations Received"/>
            </ConnectionsHeadingWrapper>
            <ConnectionsList>
              {received.map((connection) => {
                return (
                  <>
                    <ConnectionListItem
                      handleInvitation={handleInvitation}
                      professional={connection.professional}
                      connection={connection}
                      handleSelectProfessional={handleSelectProfessional}/>
                  </>
                )
              })}
            </ConnectionsList>
          </ConnectionsSectionContainer>
          <ConnectionsSectionContainer>
            <ConnectionsHeadingWrapper>
              <ConnectionsContainerHeading count={sent.length} title="Invitations Sent"/>
            </ConnectionsHeadingWrapper>
            <ConnectionsList>
              {sent.map((connection) => {
                return (
                  <>
                    <ConnectionListItem
                      handleInvitation={handleInvitation}
                      professional={connection.connectedProfessional}
                      connection={connection}
                      pending={true}
                      handleSelectProfessional={handleSelectProfessional}/>
                  </>
                )
              })}
            </ConnectionsList>
          </ConnectionsSectionContainer>
        </ConnectionsContainer>
      }
      <Modal
        isOpen={!!currentProfessional}
        onRequestClose={() => {setCurrentProfessional(null); setCurrentProfessionalUid(null)}}
        style={currentChoiceStyles}
      >
        <StyledRemoveScroll>
          <CurrentProfessionalWrapper>
            <CurrentProfessionalContent
              currentProfessional={currentProfessional}
              favourites={favourites}
              handleFavourProfessional={handleFavourProfessional}
              handleClose={() => {setCurrentProfessional(null); setCurrentProfessionalUid(null)}}
            />
          </CurrentProfessionalWrapper>
        </StyledRemoveScroll>
      </Modal>
    </InvitationsPageContainer>
  )
};

const ConnectionsList = styled.ul`
  margin-bottom: 0;
  padding-left: 0;
`;

const InvitationsPageContainer = styled(FlexRow)`
  justify-content: center;
  background-color: white;
  min-height: 500px;
  padding-bottom: 50px;
;`

const ConnectionsSectionContainer = styled(FlexColumn)`
  border-bottom: 7px solid #e8e5df;
;`

const ConnectionsHeadingWrapper = styled(FlexRow)`
  border-bottom: 2px solid #e8e5df;
`;


export const LeftArrowIcon = styled(FontAwesomeIcon)`
  align-self: center;
  margin: 2px 0 0 5px;
  color: #191919;
  &:hover {
    cursor: pointer;
  }
`;

export const HeaderText = styled(BoldedText)`
  align-self: flex-start;
  padding-right: 5px;
  font-size: 14px;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.17px;
  font-stretch: normal;
  font-style: normal;
  color: #191919;
`;

const Separator = styled.hr`
  background-color: #efedea;
  border: none;
  width: 100%;
  margin: 5px 0;
  height: 0.75em;
`;

export const ThinSeparator = styled(Separator)`
  height: 0.1em;
`;

const ConnectionsContainer = styled(FlexColumn)`
  height: auto;
  justify-content: flex-start;
  overflow: auto;
  max-width: 800px;
  background-color: white;
  margin: 0 0 50px;
  width: 100%;
  @media (min-width: 400px) {
    margin: ${props => (props.isEditable && "15px 0 15px")};
    border-radius: 5px;
  }
;`

export const ProfessionalName = styled(OverflowText)`
  color: black;
  font-size: 1em;
  font-weight: 700;
  white-space: normal;
  line-height: 20px;
`;

export const VerifiedIcon = styled.img`
  align-self: flex-start;
  margin: 0 0 0 3px;
  width: 20px;
`;

export const ProfessionType = styled(OverflowText)`
  color: black;
  font-size: 12px;
  max-width: 200px;
  align-self: flex-start;
`;

export const StarText = styled(BoldedText)`
  align-items: center;
`;


export const ProfessionalHeader = styled(FlexRow)`
  width: 100%;
  height: 100%;
`;

export const ProfessionalHeaderText = styled(FlexColumn)`
  width: 100%;
`;

export const ProfileIcon = styled(FontAwesomeIcon)`
  align-self: center;
  font-size: 2.7em;
  margin-right: 0.3em;
  color: cornflowerblue;
`;

const ProfessionalNameContainer = styled(FlexRow)`
  &:hover {
    color: royalblue;
    cursor: pointer;
  }
`;