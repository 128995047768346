import React from "react";
import styled, {css} from 'styled-components/macro'
import {Link} from "react-router-dom";
import {DebounceInput} from "react-debounce-input";
import {HashLink} from "react-router-hash-link";
import {DARK_TEXT_COLOR, PRIMARY_COLOR} from "../utils/constants";


export const BaseButton = styled.button`
  background-color: ${PRIMARY_COLOR};
  color: white;
  border: 1px solid #DBE2E9;
  font-size: 0.9em;
  font-weight: 700;
  border-radius: 3px;
  padding: 6px 12px;
  ${props => props.secondary && css`
    background-color: white;
    color: #000104;
  `}
`;

export const IconButton = styled.button`
  color: #5f5f5f;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 500;
  padding: 2px;
  &:hover {
    color: ${PRIMARY_COLOR};
  }
`;

export const DumbLink = styled.span`
  text-decoration: underline;
  color: ${PRIMARY_COLOR};
  font-size: 12px;
  &:hover {
    cursor: pointer;
  }
`;

export const FancyButton = styled(HashLink)`
  width: auto;
  display: inline-flex;
  color: #fff;
  background-color: ${PRIMARY_COLOR};
  align-items: center;
  justify-content: center;
  position: relative;
  line-height: 1.25;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  min-height: 2rem;
  font-weight: 600;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.02);
  transition: all 250ms ease;
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    transform: translateY(-1px);
    background-color: ${props => (props.secondary ? "#fff" : `${PRIMARY_COLOR}`)};
    color: ${props => (props.secondary ? `${PRIMARY_COLOR}` : "#fff")};
    border-color: ${props => (props.secondary ? "rgba(0,0,0,.15)" : props.borderColor)};
    box-shadow: ${props => (props.secondary ? "box-shadow: 0 4px 12px rgba(0,0,0,.1);" : props.boxShadow)};
  }
  ${props => props.secondary && css`
    color: rgba(0,0,0,.85);
    background-color: #fff;
    border-color: rgba(0,0,0,.1);
    box-shadow: none;
  `}
`;


export const ButtonContainer = styled.div`
  padding-right: 10px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Lato', sans-serif;
`;

export const GridParent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
`;

export const TextArea = styled.textarea`
  display: flex;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-size: 16px;
  padding: 5px 8px;
  line-height: 20px;
  min-height: 100px;
  color: ${DARK_TEXT_COLOR};
`;

export const Text = styled.div`
  display: flex;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-size: 14px;
`;

export const BoldedText = styled(Text)`
  font-weight: 600;
`;

export const ParagraphText = styled(Text)`
  font-weight: 400;
  white-space: pre-wrap;
`;

export const SecondaryText = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: rgba(14,19,24,.7);
`;

export const OverflowText = styled(ParagraphText)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SearchBarContainer = styled(FlexRow)`
  width: 100%;
  justify-content: left;
  padding: 0 0 25px 0;
`;

export const SearchInput = styled(DebounceInput)`
  border: 1px solid hsl(0,0%,80%);
  height: 2.55rem;
  width: 100%;
  max-width: 350px;
  border-radius: 3px;
  padding: 0 0.5rem;
  -webkit-appearance: none;
  outline: none;
  transition: border-color 0.3s ease;
  font-size: 16px;
  box-sizing: border-box;
`;
