import React, { useState, useEffect } from "react";
import {Button} from "./Button";
import {BaseButton, ButtonContainer, DumbLink, FlexColumn, FlexRow, SecondaryText} from "./BaseStyledComponents";
import styled, {css} from 'styled-components/macro';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {auth} from "../utils/firebase";
import firebase from "firebase/app";
import {useAuth} from "../contexts/AuthContext";
import AuthErrors from "./AuthErrors";
import { Link, useHistory } from "react-router-dom";


export const SignUp = ({ handleCloseModal, setAuthMode=null, isModal=false }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const { signup, loginWithGoogle } = useAuth();
  const history = useHistory();

  const handleClose = () => {
    setEmail("");
    setPassword("");
    setPasswordConfirm("");
    setErrors([]);
    handleCloseModal(false)
  };

  const handleProviderLogin = async (loginCall) => {
    await loginCall();
    isModal ? handleClose() : history.push("/")
  };

  const handleSubmit = async (email, password) => {
    let newErrors = [];

    if (password !== passwordConfirm) {
      newErrors = [...newErrors, "Passwords do not match"]
    }

    if (newErrors.length === 0) {
      try {
        setErrors([]);
        setLoading(true);
        let error = await signup(email, password);
        error && setErrors([...newErrors, error]);
        !error && (isModal ? handleClose() : history.push("/"))
      } catch {
        setErrors([...newErrors, "Failed to create an account"]);
      }
      setLoading(false)
    } else {
      setErrors(newErrors)
    }
  };

  return (
    <FlexColumn>
      {isModal &&
        <CancelContainer>
          <TimesIcon icon={["fa", "times"]} onClick={handleClose}/>
        </CancelContainer>
      }
      <AuthContentContainer>
        <AlternateSignUpOptions>
          <WideButtonContainer>
            <WideButton secondary onClick={() => handleProviderLogin(loginWithGoogle)}>Sign up with Google</WideButton>
          </WideButtonContainer>
        </AlternateSignUpOptions>
        <Divider>
          <DividerLine/>
          <DividerText>OR</DividerText>
          <DividerLine/>
        </Divider>
        <EmailSignUpContainer>
          {errors.length > 0 && <AuthErrors errors={errors}/>}
          <InputFieldContainer>
            <FieldText>Email</FieldText>
            <span onChange={(e) => setEmail(e.target.value)}>
              <InputField placeholder={"Email address"}/>
            </span>
          </InputFieldContainer>
          <InputFieldContainer>
            <FieldText>Password</FieldText>
            <span onChange={(e) => setPassword(e.target.value)}>
              <InputField type="password" placeholder="•••••••••••"/>
            </span>
          </InputFieldContainer>
          <InputFieldContainer>
            <FieldText>Confirm Password</FieldText>
            <span onChange={(e) => setPasswordConfirm(e.target.value)}>
              <InputField type="password" placeholder="•••••••••••"/>
            </span>
          </InputFieldContainer>
          <WideButtonContainer>
            <WideButton disabled={loading} onClick={() => handleSubmit(email, password)}>Sign Up</WideButton>
          </WideButtonContainer>
        </EmailSignUpContainer>
        <FlexColumn>
          <SecondaryText>
            Already signed up? &nbsp;
            {isModal
              ? <DumbLink onClick={() => setAuthMode("login")}>Log in</DumbLink>
              : <Link to={"/login"}>Log in</Link>
            }
          </SecondaryText>
        </FlexColumn>
      </AuthContentContainer>
    </FlexColumn>
  )
};

const AuthContentContainer = styled(FlexColumn)`
  padding: 20px;
`;

const TimesIcon = styled(FontAwesomeIcon)`
  align-self: center;
  &:hover {
    cursor: pointer
  }
`;

const CancelContainer = styled(FlexRow)`
  justify-content: flex-end;
`;

const AlternateSignUpOptions = styled(FlexColumn)`
  padding: 0 0 10px 0;
`;

const EmailSignUpContainer = styled(FlexColumn)`
  padding-bottom: 5px;
`;

const WideButton = styled(BaseButton)`
  width: 100%;
`;

const WideButtonContainer = styled.div`
  width: 100%;
  align-self: center;
`;

export const InputField = styled.input`
  border: 1px solid #DBE2E9;
  height: 2.38rem;
  width: 100%;
  border-radius: 3px;
  padding: 0 0.5rem;
  -webkit-appearance: none;
  outline: none;
  transition: border-color 0.3s ease;
  font-size: 16px;
  box-sizing: border-box;
  
  ${props => props.type === "password" && css`
    ::placeholder {
      color: lightgrey;
    }
  `}
  
`;

const InputFieldContainer = styled(FlexColumn)`
  justify-content: space-between;
  padding: 0 0 20px 0;
`;

const Divider = styled(FlexRow)`
  justify-content: space-between;
  padding: 0 0 20px 0;
`;

const DividerLine = styled.hr`
  flex: 1 0;
  height: 1px;
  border: none;
  border-bottom: 1px solid rgba(64,87,109,.07);
  margin: 7px 0;
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
`;

const DividerText= styled.span`
  margin: 0 8px;
  font-size: 12px;
  font-weight: 600;
  color: rgba(47,64,80,.25);
`;

const FieldText = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: black;
`;



