import React from "react";
import {
  BaseButton,
  BoldedText,
  DumbLink,
  FlexColumn,
  FlexRow,
  SecondaryText,
  Text
} from "./BaseStyledComponents";
import styled, {css} from 'styled-components/macro';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";


export const ResetPasswordConfirmed = ({ handleCloseModal, setAuthMode=null, isModal=false }) => {

  const history = useHistory();

  const handleClose = () => {
    handleCloseModal(false)
  };

  const handleSubmit = () => {
    isModal ? handleClose() : history.push("/")
  };

  return (
    <FlexColumn>
      {isModal &&
        <CancelContainer>
          <TimesIcon icon={["fa", "times"]} onClick={handleClose}/>
        </CancelContainer>
      }
      <AuthContentContainer>
        <HeaderTextContainer>
          <HeaderText>
            Check your email
          </HeaderText>
          <Text>
            We've just send you an email to help you reset your password!
          </Text>
        </HeaderTextContainer>
        <EmailLoginContainer>
          <WideButtonContainer>
            <WideButton onClick={handleSubmit}>Done</WideButton>
          </WideButtonContainer>
        </EmailLoginContainer>
        <FlexColumn>
          <SecondaryText>
            {isModal
              ? <DumbLink onClick={() => setAuthMode("login")}>Return to log in</DumbLink>
              : <Link to={"/login"}>Return to log in</Link>
            }
          </SecondaryText>
        </FlexColumn>
      </AuthContentContainer>
    </FlexColumn>
  )
};

const AuthContentContainer = styled(FlexColumn)`
  padding: 20px;
`;

const TimesIcon = styled(FontAwesomeIcon)`
  align-self: center;
  &:hover {
    cursor: pointer
  }
`;

const CancelContainer = styled(FlexRow)`
  justify-content: flex-end;
`;

const HeaderTextContainer = styled(FlexColumn)`
  padding: 0 0 20px 0;
`;

const HeaderText = styled(BoldedText)`
  font-weight: 800;
  font-size: 20px;
  color: black;
`;

const EmailLoginContainer = styled(FlexColumn)`
  padding-bottom: 5px;
`;

const WideButton = styled(BaseButton)`
  width: 100%;
`;

const WideButtonContainer = styled.div`
  width: 100%;
  align-self: center;
`;



