import React, {useState, useEffect} from "react";
import { Router, Route, Switch, useHistory } from "react-router-dom";
import styled, { css } from 'styled-components/macro';

import PrivateRoute from "./components/PrivateRoute";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Search from "./containers/search/Search";

import "./App.css"

import Groups from "./containers/groups/Groups";
import {FlexColumn, FlexRow} from "./components/BaseStyledComponents";
import {FOOTER_HEIGHT, SIDEBAR_WIDTH, TOP_BAR_HEIGHT} from "./utils/constants";
import Home from "./containers/home/Home";
import Footer from "./components/Footer";
import {AuthenticationForm} from "./components/AuthenticationForm";
import {ProfileViewPage} from "./components/Profile/ProfileViewPage";
import {Network} from "./containers/colleagues/Network";
import PrivacyPolicyPage from "./containers/assorted/PrivacyPolicy";


const App = () => {
  // const { loading } = useAuth();
  const [sidebar, setSidebar] = useState(false);

  const toggleSidebar = () => {
    setSidebar(!sidebar)
  };

  // if (loading) {
  //   return <Loading/>;
  // }

  return (
    <AppContainer>
      <NavBar toggleSidebar={toggleSidebar} sidebar={sidebar}/>
      <FlexColumn>
        <PageBodyContainer sidebar={sidebar}>
          <Switch>
            <Route path="/" exact component={Home}/>
            <Route path="/login" exact render={(props) =>
              <AuthenticationForm {...props} isModal={false} authMode={"login"}/>}/>
            <Route path="/signup" exact render={(props) =>
              <AuthenticationForm {...props} isModal={false} authMode={"signup"}/>}/>
            <Route path="/reset-password" exact render={(props) =>
              <AuthenticationForm {...props} isModal={false} authMode={"reset-password"}/>}/>
            <Route path="/reset-password-confirmed" exact render={(props) =>
              <AuthenticationForm {...props} isModal={false} authMode={"reset-password-confirmed"}/>}/>
            <Route path="/search" exact component={Search}/>
            <Route path="/profile/:uid" exact component={ProfileViewPage}/>
            <Route path="/invitations" exact component={Network}/>
            <Route path="/colleagues" exact render={(props) =>
              <Network {...props} allColleagues={true}/>}/>
            <Route path="/groups" exact component={Groups}/>
            <Route path="/privacy-policy" exact component={PrivacyPolicyPage}/>
            <Route path="/terms-of-use" exact component={PrivacyPolicyPage}/>
            <Route path="/match-me" exact component={() => {window.location = "https://airtable.com/shrn890qjt3BQjhaM"; return null;}}/>
            {/*<Route path="/groups/:id" component={GroupView}/>*/}
          </Switch>
        </PageBodyContainer>
        <Footer/>
      </FlexColumn>
    </AppContainer>
  );
};

export default App;

const PageBodyContainer = styled.div`
  position: relative;
  padding-top: ${TOP_BAR_HEIGHT};
  transition: 350ms;
  min-height: 100vh;
  ${props => props.sidebar && css`
    padding-left: ${SIDEBAR_WIDTH};  
    transition: 550ms;
  `}
`;

const AppContainer = styled(FlexColumn)`
  position: relative;
  min-height: 100vh;
`;

// const ContentWrap = styled.div`
//   padding-bottom: 35px;
// `;


