import React from "react";
import styled, {css} from 'styled-components/macro';

const AuthErrors = ({errors}) => {
  return (
    <>
      <ErrorsContainer>
        <ErrorsList>
          {errors.map((error) => {
            console.log("ERROR", error)
            return (<ErrorsItem>{error}</ErrorsItem>)
          })}
        </ErrorsList>
      </ErrorsContainer>
    </>
  )
};

export default AuthErrors


const ErrorsContainer = styled.div`
  background-color: #f0c7d1;
  border-radius: 3px;
  padding: 5px;
  color: black;
  margin-bottom: 10px;
`;

const ErrorsList = styled.ul`
  margin-left: -20px;
  margin-bottom: 0;
`;

const ErrorsItem = styled.li`
  font-size: 12px;
`;