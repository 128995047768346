import * as actions from "../actions/professional"

const professionalInitialState = {
  profile: {},
  loadingProfile: false,
  professionals: {},
  loadingProfessionals: false,
  submittingCorrection: false,
  medicalLocations: [],
  groups: [],
  connections: {
    all: {},
    insights: {},
    loadingConnections: false
  },
  professionTypes: null,
  active: null,
};

const professionalReducer = (state = professionalInitialState, action) => {
  switch (action.type) {
    case "RESET_STORE":
      return {
        ...professionalInitialState
      };
    case actions.UPDATE_PROFESSION_TYPES:
      return {
        ...state,
        professionTypes: action.payload.professionTypes
      };
    case actions.FETCH_PROFESSIONALS:
      return {
        ...state,
        loadingProfessionals: true,
      };
    case actions.FETCH_MORE_PROFESSIONALS:
      return {
        ...state,
        loadingMoreProfessionals: true,
      };
    case actions.FETCH_PROFESSIONAL:
      return {
        ...state,
        loadingProfessionals: true,
      };
    case actions.UPDATE_MORE_PROFESSIONALS:
      return {
        ...state,
        professionals: {
          ...state.professionals,
          ...action.payload.professionals
        },
        offset: action.payload.offset,
        count: action.payload.count,
        loadingMoreProfessionals: false,
      };
    case actions.UPDATE_PROFESSIONALS:
      return {
        ...state,
        professionals: action.payload.professionals,
        offset: action.payload.offset,
        count: action.payload.count,
        loadingProfessionals: false,
      };
    case actions.UPDATE_PROFESSIONAL:
      return {
        ...state,
        professionals: {
          ...state.professionals,
          ...action.payload.professional
        },
        loadingProfessionals: false,
      };
    case actions.FETCH_PROFILE:
      return {
        ...state,
        loadingProfile: true,
      };
    case actions.SUBMIT_CORRECTION:
      return {
        ...state,
        submittingCorrection: true,
      };
    case actions.CORRECTION_SUBMITTED:
      return {
        ...state,
        submittingCorrection: false,
      };
    case actions.UPDATE_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload.profile
        },
        loadingProfile: false,
      };
    case actions.CLEAR_PROFILE:
      return {
        ...state,
        profile: {},
      };
    case actions.UPDATE_PROFESSIONAL_NOTES:
      return {
        ...state,
        professionals: {
          ...state.professionals,
          [action.payload.professionalUid]: {
            ...state.professionals[action.payload.professionalUid],
            "userNotes": action.payload.notes
          }
        }
      };
    case actions.UPDATE_MEDICAL_LOCATIONS:
      return {
        ...state,
        medicalLocations: action.payload.medicalLocations
      };
    case actions.UPDATE_GROUPS:
      return {
        ...state,
        groups: action.payload.groups
      };
    case actions.UPDATE_GROUP:
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.payload.groupUid]: action.payload.groupDetails
        }
      };
    case actions.UPDATE_EDITED_GROUP:
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.payload.groupUid]: {
            ...state.groups[action.payload.groupUid],
            "name": action.payload.groupName,
            "description": action.payload.groupDescription,
          }
        }
      };
    case actions.FETCH_CONNECTIONS:
      return {
        ...state,
        connections: {
          ...state.connections,
          loadingConnections: true
        }
      };
    case actions.UPDATE_DELETED_CONNECTION:
      let {[action.payload.connectionId]: value, ...remainingConnections} = state.connections.all
      return {
        ...state,
        connections: {
          ...state.connections,
          all: remainingConnections
        }
      };
    case actions.UPDATE_CONNECTION:
      return {
        ...state,
        connections: {
          ...state.connections,
          all: {
            ...state.connections.all,
            [action.payload.connection.id]: action.payload.connection
          }
        }
      };
    case actions.UPDATE_CONNECTIONS:
      return {
        ...state,
        connections: {
          ...state.connections,
          all: action.payload.connections,
          loadingConnections: false
        }
      };
    case actions.UPDATE_CONNECTIONS_INSIGHTS:
      return {
        ...state,
        connections: {
          ...state.connections,
          insights: action.payload.insights
        }
      };
    default:
      return state
  }
};

export default professionalReducer;