import React, { useState, useEffect } from "react";
import {useDispatch} from "react-redux";
import {BaseButton, BoldedText, FlexColumn, FlexRow, SearchBarContainer} from "../BaseStyledComponents";
import styled from 'styled-components/macro';

import {editProfileInfo, fetchMedicalLocations} from "../../redux/actions/professional";
import {TextArea} from "../BaseStyledComponents";
import AsyncSelect from 'react-select/async';
import {useFetch} from "../../utils/hooks";
import Select from "react-select";
import {Location} from "./ProfileView";


const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "100%"
  }),
};

const editFieldMappings = {
  "interestAreas": {
    "name": "Areas of Interest",
    "placeholder": "Enter your areas of interest and specialties",
    "boxSize": "100px"
  },
  "additionalInfo": {
    "name": "Additional Information",
    "placeholder": "Enter any additional information such as your education and background",
    "boxSize": "150px"
  },
  "waitTimeText": {
    "name": "Wait Time",
    "placeholder": "Enter your typical wait times",
    "boxSize": "100px"
  },
  "feesText": {
    "name": "Fees",
    "placeholder": "Describe your fees, e.g. service type/duration/cost",
    "boxSize": "100px"
  },
  "bulkBillingText": {
    "name": "Bulk Billing",
    "placeholder": "Enter Yes/No/Maybe and any conditional information",
    "boxSize": "100px"
  },
  "location": {
    "name": "Select Location",
    "placeholder": "Search by name or address",
    "boxSize": null
  },
}

const getMedicalLocationString = (medicalLocation) => {
  return `${medicalLocation.name} - ${medicalLocation.location.streetNumber} ${medicalLocation.location.streetName},
          ${medicalLocation.location.suburb}, ${medicalLocation.location.state}, ${medicalLocation.location.postcode}`
}

const formatLocationOptions = (medicalLocations) => {
  return medicalLocations.map((medicalLocation) => {
    medicalLocation.value =  medicalLocation.id
    medicalLocation.label = getMedicalLocationString(medicalLocation)

    return medicalLocation
  })
}

export const EditProfileInfo = ({ handleModal, user, fieldsList}) => {

  const dispatch = useDispatch();

  const [interestAreas, setInterestAreas] = useState(user.description);
  const [additionalInfo, setAdditionalInfo] = useState(user.additionalInformation);
  const [waitTimeText, setWaitTimeText] = useState(user.waitTimesText);
  const [feesText, setFeesText] = useState(user.feesText);
  const [bulkBillingText, setBulkBillingText] = useState(user.bulkBillingText);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [locationResponse, locationLoading, , locationFetch] = useFetch(process.env.REACT_APP_API + `/api/medical-locations/search`, null, [],{runOnInitialRender: false, debounce: 1000});


  useEffect(() => {
    if (locationResponse) {
      setLocationOptions(formatLocationOptions(locationResponse))
    }
  }, [locationResponse])

  const handleSubmit = async () => {
    let editedFields = {};
    if (fieldsList.indexOf("interestAreas") >= 0) {
     editedFields["description"] = interestAreas;
    }
    if (fieldsList.indexOf("additionalInfo") >= 0) {
      editedFields["additionalInformation"] = additionalInfo;
    }
    if (fieldsList.indexOf("waitTimeText") >= 0) {
      editedFields["waitTimesText"] = waitTimeText;
    }
    if (fieldsList.indexOf("feesText") >= 0) {
      editedFields["feesText"] = feesText;
    }
    if (fieldsList.indexOf("bulkBillingText") >= 0) {
      editedFields["bulkBillingText"] = bulkBillingText;
    }
    if (fieldsList.indexOf("location") >= 0) {
      editedFields["location"] = selectedLocation.id;
    }

    dispatch(editProfileInfo(user.uid, editedFields))
    handleModal(false)
  };

  const getField = (field) => {
    switch (field) {
      case "interestAreas":
        return [interestAreas, setInterestAreas];
      case "additionalInfo":
        return [additionalInfo, setAdditionalInfo];
      case "waitTimeText":
        return [waitTimeText, setWaitTimeText];
      case "feesText":
        return [feesText, setFeesText];
      case "bulkBillingText":
        return [bulkBillingText, setBulkBillingText];
      default:
        return null
    }
  }

  const handleInputChange = (inputValue, { action }) => {
    switch (action) {
      case 'input-change':
        setSearchTerm(inputValue);
        locationFetch({"q": inputValue})
        return;
      case 'menu-close':
        setSearchTerm("");
        setLocationOptions([])
        return;
      default:
        return;
    }
  }

  const handleSelectedLocation = (location) => {
    setSearchTerm("");
    setSelectedLocation(location)
  };

  const checkForUnsavedNotes = () => {
    let unsavedNotes = false
    if (user.waitTimesText !== waitTimeText) {
      unsavedNotes = true
    } else if (user.feesText !== feesText) {
      unsavedNotes = true
    } else if (user.bulkBillingText !== bulkBillingText) {
      unsavedNotes = true
    } else if (user.description !== interestAreas) {
      unsavedNotes = true
    } else if (user.additionalInformation !== additionalInfo) {
      unsavedNotes = true
    }
    handleModal(unsavedNotes)
  }

  return (
    <Container>
      <EditContainer>
        {fieldsList.map((field) => {
          let fieldInfo = editFieldMappings[field]
          if (field === "location") {
            return (
              <EditFieldContainer>
                <HeaderText>{fieldInfo.name}</HeaderText>
                <MedicalLocationSearchBarContainer>
                  <Select
                    options={locationOptions}
                    value={searchTerm}
                    inputValue={searchTerm}
                    onChange={(location, action) => handleSelectedLocation(location)}
                    onInputChange={handleInputChange}
                    styles={customStyles}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    noOptionsMessage={(input) => {return (input && input.length > 0 ? "No Options" : null)}}
                    isSearchable
                    name="location"
                    placeholder={fieldInfo.placeholder}
                  />
                </MedicalLocationSearchBarContainer>
                {selectedLocation &&
                <SelectedLocationContainer>
                  <HeaderText>Selected Location</HeaderText>
                  <Location medicalLocation={selectedLocation}/>
                </SelectedLocationContainer>
                }
              </EditFieldContainer>
            )
          } else {
            let [fieldValue, func] = getField(field)
            return (
              <EditFieldContainer>
                <HeaderText>{fieldInfo.name}</HeaderText>
                <NotesTextArea
                  boxSize={fieldInfo.boxSize}
                  placeholder={fieldInfo.placeholder}
                  onChange={(e) => func(e.target.value)}
                >
                  {fieldValue}
                </NotesTextArea>
              </EditFieldContainer>
            )
          }
        })}
      </EditContainer>
      <ActionsContainer>
        <BaseButton onClick={checkForUnsavedNotes} secondary>Close</BaseButton>
        <BaseButton onClick={handleSubmit}>Save</BaseButton>
      </ActionsContainer>
    </Container>
  )
};


const Container = styled(FlexColumn)`
  width: 100%;  
`;

const EditContainer = styled(FlexColumn)`
  margin-bottom: 50px;
  overflow: auto;
  width: 100%;
  padding: 20px;
  height: 90%;
`;

const ActionsContainer = styled(FlexRow)`
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: white;
  z-index: 450;
  box-shadow: 0 0 4px 0 #d2d9e5;
  padding: 8px 16px;
`;

const HeaderText = styled(BoldedText)`
  align-self: flex-start;
  padding: 0 5px 5px 0;
  font-size: 14px;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.17px;
  font-stretch: normal;
  font-style: normal;
  color: #191919;
`;

const NotesTextArea = styled(TextArea)`
  height: ${props => props.boxSize};
  overflow-y: scroll;
`;

const EditFieldContainer = styled(FlexColumn)`
  padding: 0 0 20px;
`;


const MedicalLocationSearchBarContainer = styled(SearchBarContainer)`
  padding: 0;
  color: #6d6d6d;
  font-size: 16px;
`;

const SelectedLocationContainer = styled(FlexColumn)`
  padding-top: 20px;
`;



