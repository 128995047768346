// Colors
export const PRIMARY_COLOR = "#2f80ed";
export const DARK_COLOR_VARIATION = "#174077";
export const LIGHT_COLOR_VARIATION = "#eff9ff";

export const DARK_TEXT_COLOR = "#283343";


export const SIDEBAR_WIDTH = "200px";
export const TOP_BAR_HEIGHT = "55px";
export const FOOTER_HEIGHT = "35px";

export const breakpoints = {
  "phoneOnly": "600px",
  "tabletPortraitUp": "800px",
  "tabletLandscapeUp": "1200px",
  "desktopUp": "1200px",
  "bigDesktopUp": "1800px",
};

// State initialisation
export const distanceOptions = [
  {value: "5km", label: "5km"},
  {value: "20km", label: "20km"},
  {value: "50km", label: "50km"},
  {value: "100km", label: "100km"},
  {value: "200km", label: "200km"},
];

export const billingOptions = [
  {value: "ALWAYS", label: "Always Bulk Bill"},
  {value: "CONDITIONAL", label: "Bulk Billing Available"},
  {value: "DISCOUNTS_ONLY", label: "Discounted Fees Only"},
];

export const waitTimeOptions = [
  {value: 3, label: "< 3 Days", range: [0, 2]},
  {value: 7, label: "< 1 Week", range: [3, 6]},
  {value: 14, label: "< 2 Weeks", range: [7, 13]},
  {value: 28, label: "< 4 Weeks", range: [14, 28]},
];

export const feeAmountOptions = [
  {value: 100, label: "<$100"},
  {value: 150, label: "<$150"},
  {value: 200, label: "<$200"},
];