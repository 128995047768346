import React, { useState, useEffect } from "react";
import styled, {css} from 'styled-components/macro';
import {SignUp} from "./SignUp";
import {Login} from "./Login";
import {ResetPassword} from "./ResetPassword";
import {ResetPasswordConfirmed} from "./ResetPasswordConfirmed";
import {FlexColumn, FlexRow} from "./BaseStyledComponents";


export const AuthenticationForm = ({ handleCloseModal=null, authMode, setAuthMode=null, isModal=true }) => {

  const props = {
    setAuthMode: setAuthMode,
    isModal: isModal,
    handleCloseModal: handleCloseModal
  };

  const authModeMap = {
    "login": <Login {...props}/>,
    "signup": <SignUp {...props}/>,
    "reset-password": <ResetPassword {...props}/>,
    "reset-password-confirmed": <ResetPasswordConfirmed {...props}/>
  };

  return (
    <AuthRowContainer>
      <AuthContainer>
        {authModeMap[authMode]}
      </AuthContainer>
    </AuthRowContainer>
  )
};

const AuthContainer = styled(FlexColumn)`
  width: 100%;
  padding: 0 8px;
  overflow: auto;
  ${props => !props.isModal && css`
    align-self: center;
    max-width: 450px;
  `}
`;

const AuthRowContainer = styled(FlexRow)`
  justify-content: center;
  width: 100%;
`;
