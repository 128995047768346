import React, {useEffect, useState} from 'react';
import styled, { css } from 'styled-components/macro';
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-modal";
import Select from 'react-select';
import  { RemoveScroll } from 'react-remove-scroll';

import './Search.css';
import {CurrentProfessionalContent} from "../../components/CurrentProfessionalContent";
import {ProfessionalListItem} from "../../components/ProfessionalListItem";
import {
  BoldedText,
  FlexColumn,
  FlexRow,
} from "../../components/BaseStyledComponents";
import {MoveToGroupSelector} from "../../components/MoveToGroupSelector";
import {
  addProfessionalsToGroup,
  favourProfessional, fetchConnectionsInsights,
  fetchFavourites,
  unfavourProfessional
} from "../../redux/actions/professional";
import { SignInPrompt } from "../../components/SignInPrompt";
import Loading from "../../components/Loading";
import {clickEvent, submitEvent} from "../../utils/amplitude";
import {useAuth} from "../../contexts/AuthContext";


const addToGroupCustomStyles = {
  content : {
    top                   : '40%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    height                : '315px',
    width                 : '400px',
    transform             : 'translate(-50%, -50%)'
  },
  overlay : {
    zIndex                : 500,
    backgroundColor       : "rgb(27 20 20 / 75%)",
  },
};

const signInPromptCustomStyles = {
  content : {
    top                   : '40%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    height                : 'auto',
    width                 : '95%',
    maxWidth              : '400px',
    transform             : 'translate(-50%, -50%)',
    padding               : "10px 5px",
  },
    overlay : {
      zIndex                : 500,
      backgroundColor       : "rgb(27 20 20 / 75%)",
    },
};

export const currentChoiceStyles = {
  content : {
    position              : 'relative',
    top                   : '50%',
    left                  : '50%',
    height                : '95%',
    maxHeight             : '650px',
    width                 : '95%',
    maxWidth              : '550px',
    transform             : 'translate(-50%, -50%)',
    padding               : "0",
  },
  overlay : {
    zIndex                : 400,
    backgroundColor       : "rgb(27 20 20 / 75%)",
  },
};

const customStyles = {
  container: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const width = "100%";

    return {...provided, width, opacity, transition };
  },
  indicatorsContainer: (provided, state) => ({
    ...provided,
    marginLeft: "-5px"
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#005ce0"
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#fff"
  }),
  menu: (provided, state) => ({
    ...provided,
    fontSize: "12px"
  }),
};

const actionsOptions = [
  {"value": "add-to-group", "label": "Add to group"},
  // {"value": "share", "name": "Share"},
];

export const formatGroups = (groups) => {
  let formattedGroups = [];
  if (Object.keys(groups).length > 0) {
    Object.entries(groups).forEach(([uid, group]) => {
      formattedGroups.push({value: uid, label: group.name, description: group.description})
    });
  }

  return formattedGroups.sort((a, b) => {
    const aName = a.label.toLowerCase();
    const bName = b.label.toLowerCase();
    if (aName < bName) return -1;
    if (aName > bName) return 1;
    return 0;
  });
};


const SearchResults = ({
   favouritesToggle, setFavouritesToggle, filteredProfessionals, selectedProfessionals, setSelectedProfessionals,
   handleSelectedProfessional, loadingProfessionals, loadingMoreProfessionals, setAuthModalOpen, lastProfessionalRef,
   showActions=false
}) => {

  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const groups = useSelector(state => state.professionals.groups);
  const searchOffset = useSelector(state => state.professionals.offset);
  const searchCount = useSelector(state => state.professionals.count);
  const connectionsInsights = useSelector(state => state.professionals.connections.insights);

  const groupsOptions = formatGroups(groups);
  const favouriteGroup = Object.entries(groups).filter(([uid, group]) => {
    return group.name === "Favourites"
  });

  let favouritesUid, favourites;
  if (favouriteGroup.length === 1) {
    [favouritesUid, favourites] = favouriteGroup[0]
  }

  const [currentProfessional, setCurrentProfessional] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [signInModalOpen, setSignInModalOpen] = useState(false);


  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchConnectionsInsights());
      dispatch(fetchFavourites());
    }
  }, [isAuthenticated]);

  const handleAddToGroupSubmit = (group) => {
    dispatch(addProfessionalsToGroup(selectedProfessionals, group.value));
    setSelectedAction(null);
    setSelectedProfessionals(new Set())
  };

  const handleFavourProfessional = (professional) => {
    let submitOptions = {
      "professional": professional.firstName + " " + professional.lastName,
      "profession": professional.professionType
    };
    if (favourites && favourites.professionalsUids.indexOf(professional.uid) >= 0) {
      submitEvent("unfavour-professional", submitOptions);
      dispatch(unfavourProfessional(professional.uid, favouritesUid))
    } else {
      submitEvent("favour-professional", submitOptions);
      dispatch(favourProfessional(professional.uid, favouritesUid))
    }
  };

  const handleCurrentProfessional = (professionals, professional) => {
    clickEvent(
      "professional-profile",
      {
        "professional": professional.firstName + " " + professional.lastName,
        "profession": professional.professionType
      }
    );
    let currentProfessional = professionals.filter(item => item.uid === professional.uid)[0];
    setCurrentProfessional(currentProfessional);
  };

  const handleLogin = () => {
    setSignInModalOpen(false);
    setAuthModalOpen(true)
  };

  const filterFavourites = (professionals) => {
    let filteredFavourites = professionals;
    filteredFavourites = filteredFavourites.filter(professional => {
      return favourites && favourites.professionalsUids.indexOf(professional.uid) >= 0
    });

    return filteredFavourites
  };

  let displayProfessionals = filteredProfessionals;
  if (favouritesToggle) {
    displayProfessionals = filterFavourites(filteredProfessionals)
  }

  return (
    <SearchResultsContainer>
      <ResultsCountContainer hidden={!searchCount | loadingProfessionals}>
        <ResultsCountText>{`Showing ${Object.keys(filteredProfessionals).length} of ${searchCount} results`}</ResultsCountText>
      </ResultsCountContainer>
      {loadingProfessionals
        ? <ProfessionalResultsContainer noResults>
            <Loading fullScreen={false}/>
        </ProfessionalResultsContainer>
        : filteredProfessionals.length > 0
          ? <ProfessionalResultsContainer>
            <ProfessionalResultsContentContainer>
              <ProfessionalResultsListContainer>
                {/*{showActions &&*/}
                {/*<ActionsContainer>*/}
                {/*<SelectionsContainer>*/}
                {/*<CheckboxContainer>*/}
                {/*<i className={"fa fa-" + (selectedProfessionals.size > 0 ? "check-square" : "square-o")}*/}
                {/*style={{"font-size": "larger"}}/>*/}
                {/*</CheckboxContainer>*/}
                {/*<SelectedText>*/}
                {/*{selectedProfessionals ? selectedProfessionals.size : "0"}*/}
                {/*{selectedProfessionals.size === 1 ? " Professional" : " Professionals"} Selected*/}
                {/*</SelectedText>*/}
                {/*</SelectionsContainer>*/}
                {/*<GroupActions>*/}
                {/*<Select*/}
                {/*options={actionsOptions}*/}
                {/*value={selectedAction}*/}
                {/*onChange={setSelectedAction}*/}
                {/*isSearchable={false}*/}
                {/*isDisabled={!selectedProfessionals || selectedProfessionals.size === 0}*/}
                {/*components={{IndicatorSeparator: () => null}}*/}
                {/*styles={customStyles}*/}
                {/*name="actions"*/}
                {/*placeholder="Actions"*/}
                {/*className="basic-multi-select"*/}
                {/*classNamePrefix="select"*/}
                {/*/>*/}
                {/*</GroupActions>*/}
                {/*</ActionsContainer>*/}
                {/*}*/}
                {/*{isAuthenticated &&*/}
                {/*  <FavouritesToggleContainer>*/}
                {/*    <SelectionsContainer>*/}
                {/*      <ToggleContainer>*/}
                {/*        <Toggle*/}
                {/*          onClick={() => setFavouritesToggle(!favouritesToggle)}*/}
                {/*          on={favouritesToggle}*/}
                {/*          className={"fa fa-" + (favouritesToggle ? "toggle-on" : "toggle-off")}/>*/}
                {/*      </ToggleContainer>*/}
                {/*      <FavouritesText>Only Show Saved</FavouritesText>*/}
                {/*    </SelectionsContainer>*/}
                {/*  </FavouritesToggleContainer>*/}
                {/*}*/}
                <ProfessionalList>
                  {displayProfessionals.map((professional, index) => {
                    return (
                      <>
                        <ProfessionalListItem
                          key={professional.uid}
                          connectionsInsights={connectionsInsights[professional.uid]}
                          professional={professional}
                          professionals={filteredProfessionals}
                          favourites={favourites}
                          setSignInModalOpen={setSignInModalOpen}
                          handleFavourProfessional={handleFavourProfessional}
                          handleCurrentProfessional={handleCurrentProfessional}
                          selectedProfessionals={selectedProfessionals}
                          handleSelectedProfessional={handleSelectedProfessional}
                        />
                        {filteredProfessionals.length === index + 1 && <div ref={lastProfessionalRef}/>}
                      </>
                    )
                  })}
                </ProfessionalList>
                {loadingMoreProfessionals && <Loading fullScreen={false}/>}
              </ProfessionalResultsListContainer>
            </ProfessionalResultsContentContainer>
          </ProfessionalResultsContainer>
          : <ProfessionalResultsContainer noResults>
            Your search criteria have returned 0 results
          </ProfessionalResultsContainer>
      }
      <Modal
        isOpen={!!currentProfessional}
        onRequestClose={() => setCurrentProfessional(null)}
        style={currentChoiceStyles}
      >
        <StyledRemoveScroll>
          <CurrentProfessionalWrapper>
            <CurrentProfessionalContent
              currentProfessional={currentProfessional}
              favourites={favourites}
              setSignInModalOpen={setSignInModalOpen}
              handleFavourProfessional={handleFavourProfessional}
              handleClose={() => setCurrentProfessional(null)}
            />
          </CurrentProfessionalWrapper>
        </StyledRemoveScroll>
      </Modal>
      <Modal
        isOpen={!!(selectedAction && selectedAction.value === "add-to-group")}
        onRequestClose={() => setSelectedAction(null)}
        style={addToGroupCustomStyles}
      >
        <RemoveScroll>
          <MoveToGroupSelector
            groups={groupsOptions}
            handleSubmit={handleAddToGroupSubmit}
            handleClose={() => setSelectedAction(null)}
          />
        </RemoveScroll>
      </Modal>
      <Modal
        isOpen={signInModalOpen}
        onRequestClose={() => setSignInModalOpen(false)}
        style={signInPromptCustomStyles}
      >
        <RemoveScroll>
          <SignInPrompt
            message={"Sign in to keep track of your favourite professionals and take personal notes on them"}
            login={handleLogin}
            handleClose={() => setSignInModalOpen(false)}
          />
        </RemoveScroll>
      </Modal>
    </SearchResultsContainer>
  )
};

export default SearchResults;


const SearchResultsContainer = styled(FlexColumn)`
  width: 100%;
  justify-content: center;
`;


const ResultsCountContainer = styled(FlexRow)`
  width: 95%;
  justify-content: center;
  align-self: center;
  max-width: 1150px;
  ${props => props.hidden && css`
    display: none
  `}
`;

const ResultsCountText = styled(BoldedText)`
  width: 100%;
  color: #191919;
  font-weight: 500;
  font-size: 14px;
  align-self: flex-start
`;

const ProfessionalResultsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  width: 95%;
  max-width: 1150px;
  text-align: center;
  border-top: solid;
  border-top-color: #f5e8e8;
  border-width: thick;
  ${props => props.noResults && css`
    padding-top: 100px;
    padding-bottom: 100px;
    font-size: 16px;
    font-weight: bold;
    color: #9c7676;
  `};
`;

const ProfessionalResultsContentContainer = styled(FlexRow)`
  width: 100%;
`;

const ProfessionalResultsListContainer = styled.div`
  width: 100%;
`;

const FavouritesToggleContainer = styled(FlexRow)`
  padding: 10px 20px 10px 40px;
  justify-content: space-between;
  border-bottom: 2px solid #d4bfbf;
`;

const SelectionsContainer = styled(FlexRow)`
  justify-content: left;
`;

const ToggleContainer = styled.div`
  align-self: center;
`;

const Toggle = styled.i`
  font-size: x-large;
  transition: 350ms;
  &:hover {
    cursor: pointer;
  }
  ${props => props.on && css`
    color: green;
  `}
`;

const FavouritesText = styled(BoldedText)`
  align-self: center;
  font-size: 16px;
  color: #766f6f;
  padding-left: 15px;
`;

const ProfessionalList = styled.ul`
  text-align: left;
  box-sizing: content-box;
  padding: 0 0.2em;
`;

export const CurrentProfessionalWrapper = styled(FlexColumn)`
  color: black;
  height: 100%;
  width: 100%;
`;

export const StyledRemoveScroll = styled(RemoveScroll)`
  display: flex;
  height: 100%;
  width: 100%;
`;
