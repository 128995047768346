import React from "react";
import styled, {css} from 'styled-components/macro';
import {Link, useHistory} from "react-router-dom";
import {BoldedText, FlexColumn, FlexRow, GridParent, Text} from "./BaseStyledComponents";
import {DARK_COLOR_VARIATION, FOOTER_HEIGHT} from "../utils/constants";
import {LogoImage} from "./NavBar";
import {HashLink} from "react-router-hash-link";


const Footer = () => {
  const history = useHistory()

  return (
    <FooterWrapper>
      <FooterContainer>
        <Container>
          <FooterLogoContainer>
            <HashLink to="/#">
              <FooterLogo src={"https://storage.googleapis.com/nexos-branding/icons/NexosLogoLongTextWhite.svg"} alt={"Logo"}/>
            </HashLink>
          </FooterLogoContainer>
          <FooterSections>
            <FooterSection>
              <FooterSectionTitle>Resources</FooterSectionTitle>
              <FooterSectionLink link to="/#about">About Nexos</FooterSectionLink>
            </FooterSection>
            <FooterSection>
              <FooterSectionTitle>Professionals</FooterSectionTitle>
              <FooterSectionLink to="/#for-gps">General Practitioners</FooterSectionLink>
              <FooterSectionLink to="/#for-health-professionals">Specialists</FooterSectionLink>
              <FooterSectionLink to="/#for-health-professionals">Allied Health</FooterSectionLink>
            </FooterSection>
            <FooterSection>
              <FooterSectionTitle>Contact Us</FooterSectionTitle>
              <FooterSectionText>info@nexoshealth.com</FooterSectionText>
              <FooterSectionText>0403 863 967</FooterSectionText>
            </FooterSection>
            <FooterSection>
              <FooterSectionTitle>Legal</FooterSectionTitle>
              <FooterSectionLink to="/privacy-policy#">Privacy Policy</FooterSectionLink>
              {/*<FooterSectionLink link onClick={() => history.push("/terms-of-use#")}>Terms of Use</FooterSectionLink>*/}
            </FooterSection>
          </FooterSections>
        </Container>
        <IconsText>Images provided by&nbsp;<a href="https://www.flaticon.com/authors/freepik">Freepik from Flaticon</a></IconsText>
      </FooterContainer>
    </FooterWrapper>
  );
}

export default Footer;

const Container = styled(FlexRow)`
  justify-content: space-between;
  width: 100%;
  padding: 20px 0 0;
  flex-wrap: wrap;
  @media (min-width: 1100px) {
    padding: 20px 50px 0;
  }
`;

const FooterSections = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  row-gap: 5px;
  column-gap: 20px;
  @media (min-width: 440px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1100px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const FooterSection = styled(FlexColumn)`
  padding: 0 0 20px 0;
`;

const FooterLogoContainer = styled(FlexColumn)`
  height: 40px;
  margin: 0 50px 40px 0;
  border-bottom: solid 0.1px darkgrey;
  padding-bottom: 5px;
  @media (min-width: 700px) {
    margin: 0 50px 10px 0;
  }
`;

const FooterSectionTitle = styled(BoldedText)`
  text-transform: uppercase;
  margin: 0 0 10px;
  font-family: 'Selawik', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.6px;
  text-align: left;
  color: #ffffff;
  @media (min-width: 440px) {
    margin: 0 0 10px
  }
`;

const FooterSectionLink = styled(HashLink)`
  font-style: normal;
  padding-bottom: 7px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  &:hover {
    color: grey;
    ${props => props.noLink && css`
      cursor: auto;
      color: #ffffff;
    `}
  }
`;


const FooterSectionText = styled(BoldedText)`
  font-style: normal;
  padding-bottom: 7px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
`;

const FooterContainer = styled(FlexColumn)`
  max-width: 1000px;
  padding: 20px 0 0;
  width: 100%;
  @media (max-width: 1100px) {
    padding: 20px 50px 0;  
  }
`;

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: ${DARK_COLOR_VARIATION};
  margin-top: 5rem;
`;

export const FooterLogo = styled.img`
  height: 32px;
`;

const FooterLink = styled(Link)`
  color: black;
  height: 100%;
  padding: 3px 20px; 
  margin: 0; 
  text-align:center;
  font-family: "Lato", Helvetica, Arial, serif;
  font-style: normal;
  font-size: 14px;
`;

const IconsText = styled(Text)`
  font-size: 10px;
  align-self: flex-end;
  padding: 3px 0;
  justify-content: flex-end;
`;


