import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components/macro';

import logo from "../../assets/logo.svg";
import documents from "../../assets/documents.png";
import medicaldoctor from "../../assets/medicaldoctor.png";
import star from "../../assets/star.png";
import "./Home.css"
import {
  BoldedText,
  ButtonContainer,
  FancyButton,
  FlexColumn,
  FlexRow, ParagraphText,
  Text
} from "../../components/BaseStyledComponents";
import {clickEvent} from "../../utils/amplitude";
import {useAuth} from "../../contexts/AuthContext";
import {useDispatch} from "react-redux";
import {fetchProfessionTypes} from "../../redux/actions/professional";
import Loading from "../../components/Loading";
import {ThinSeparator} from "../../components/CurrentProfessionalContent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import {DARK_TEXT_COLOR, LIGHT_COLOR_VARIATION, PRIMARY_COLOR} from "../../utils/constants";
import {HashLink} from "react-router-hash-link";


const Home = () => {
  const { loading, isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchProfessionTypes())
  }, [])

  if (loading) {
    return <Loading fullScreen={false}/>
  }

  return (
    <HomeContainer>
      <SectionWrapper>
        <ColumnsSection>
          <LeftSection>
            <LargeSectionTitle>Find the best health match</LargeSectionTitle>
            <SectionSubText>
              Search across our extensive directory of health professionals to find the right provider or let us help you
              find a match
            </SectionSubText>
            <CallToActionContainer>
              <ButtonContainer>
                <FancyButton to="/#for-patients" onClick={() => {clickEvent("match-me", {"location": "hero"})}}>
                  Get Matched
                </FancyButton>
              </ButtonContainer>
              <FancyButton secondary to="/search#" onClick={() => {clickEvent("search", {"location": "hero"})}}>
                Search
              </FancyButton>
            </CallToActionContainer>
          </LeftSection>
          <RightSection>
            <ImageContainer>
              <LargeImage src="https://storage.googleapis.com/nexos-branding/icons/FindHealthMatch.svg"/>
            </ImageContainer>
          </RightSection>
        </ColumnsSection>
      </SectionWrapper>
      <ShortThinSeparator/>
      <SectionWrapper>
        <ColumnsSection>
          <LeftSection>
            <ImageContainer>
              <LargeImage src="https://storage.googleapis.com/nexos-branding/icons/MentalHealthBrain.svg"/>
            </ImageContainer>
          </LeftSection>
          <RightSection extraPadding id="for-patients">
            <SectionHeader>For Patients</SectionHeader>
            <SectionTitle>Let's help you find a psychologist</SectionTitle>
            <SectionSubText noPadding>
              Stop calling countless psychologists trying to find an available appointment. Our friendly and knowledgable staff can help you find a great psychologist to see you as soon as possible.
            </SectionSubText>
           <LinkContainer padding>
              <LinkButton onClick={() => {window.location.replace("https://03wrqbmdr5h.typeform.com/to/zVdK0gQr"); clickEvent("fill-form", {"location": "for-patients"})}}>Get in contact</LinkButton>
              <LinkArrow icon={["fas", "long-arrow-alt-right"]}/>
            </LinkContainer>
            <SectionTitle medium>Looking for something else?</SectionTitle>
            <SectionSubText noPadding>
              We are currently only matching patients with psychologists, if you are looking for other health professions or wish to search for your own psychologist, please refer to our search directory.
            </SectionSubText>
            <LinkContainer>
              <LinkButton to="/search#" onClick={() => {clickEvent("search", {"location": "for-patients"})}}>Search</LinkButton>
              <LinkArrow icon={["fas", "long-arrow-alt-right"]}/>
            </LinkContainer>
          </RightSection>
        </ColumnsSection>
      </SectionWrapper>
      <ShortThinSeparator/>
      <SectionWrapper id="for-gps">
        <ColumnsSection noPadding>
          <LeftSection>
            <SectionHeader>For General Practitioners</SectionHeader>
            <SectionTitle>Save time when making referrals</SectionTitle>
            <SectionSubText>
              There are so many specialties, procedures, and treatments within medicine that it can be hard and time consuming to navigate. Nexos Health helps you identify and keep track of the best health providers for your referral network
            </SectionSubText>
            <CallToActionContainer>
              {!isAuthenticated &&
              <ButtonContainer>
                <FancyButton to="/signup#" onClick={() => clickEvent("signup", {"location": "for-gps"})}>
                  Sign Up
                </FancyButton>
              </ButtonContainer>
              }
              <FancyButton secondary={!isAuthenticated} to="/search#" onClick={() => clickEvent("search", {"location": "for-gps"})}>
                Search
              </FancyButton>
            </CallToActionContainer>
          </LeftSection>
          <RightSection>
            <ImageContainer>
              <LargeImage src="https://storage.googleapis.com/nexos-branding/icons/HealthProfessionalTeam.svg"/>
            </ImageContainer>
          </RightSection>
        </ColumnsSection>
        <SingleSection padding>
          <FeaturesContainer>
            <FeatureContainer>
              <FeatureIcon src="https://storage.googleapis.com/nexos-branding/icons/SingleDoctor.svg" alt="medical-doctor"/>
              <FeatureTitle>Superior Search</FeatureTitle>
              <FeatureSubText>
                Search over thousands of health professionals on a variety of filters such as keyword, location, or wait time to find exactly who you are looking for, fast.
              </FeatureSubText>
            </FeatureContainer>
            <FeatureContainer>
              <FeatureIcon src="https://storage.googleapis.com/nexos-branding/icons/HealthFavourite.svg" alt="medical-doctor"/>
              <FeatureTitle>Favourites</FeatureTitle>
              <FeatureSubText>
                Save time and “favourite” health professionals so you can quickly find them for future referrals.
              </FeatureSubText>
            </FeatureContainer>
            <FeatureContainer>
              <FeatureIcon src="https://storage.googleapis.com/nexos-branding/icons/DoctorNotes.svg" alt="medical-doctor"/>
              <FeatureTitle>Take Notes</FeatureTitle>
              <FeatureSubText>
                Take your own, personal notes on health professionals for your future reference.
              </FeatureSubText>
            </FeatureContainer>
            <FeatureContainer>
              <FeatureIcon src="https://storage.googleapis.com/nexos-branding/icons/SharingFiles.svg" alt="medical-doctor"/>
              <FeatureTitle>Share Information With Colleagues</FeatureTitle>
              <FeatureSubText>
                Sign Up to Nexos and quickly see what your colleagues think of other health professionals within your network
              </FeatureSubText>
            </FeatureContainer>
          </FeaturesContainer>
        </SingleSection>
      </SectionWrapper>
      <ShortThinSeparator/>
      <SectionWrapper id="for-health-professionals">
        <ColumnsSection>
          <LeftSection>
            <SectionHeader>For Specialists and Allied Health</SectionHeader>
            <SectionTitle>Join the network</SectionTitle>
            <SectionSubText>
              Develop your online presence and be quickly found by potential patients who need you most. Any health professional can sign up and edit their public profile for free.
            </SectionSubText>
            {!isAuthenticated &&
            <CallToActionContainer>
              <ButtonContainer>
                <FancyButton to="/signup#" onClick={() => clickEvent("signup", {"location": "for-health-professionals"})}>
                  Sign Up
                </FancyButton>
              </ButtonContainer>
            </CallToActionContainer>
            }
          </LeftSection>
          <RightSection oversizedBrowser>
            <ImageContainer oversizedBrowser>
              <LargeImage src="https://storage.googleapis.com/nexos-branding/icons/BrowserProfileFading.svg"/>
            </ImageContainer>
          </RightSection>
        </ColumnsSection>
        <SingleSection padding>
          <FeaturesContainer>
            <FeatureContainer>
              <FeatureIcon src="https://storage.googleapis.com/nexos-branding/icons/MultipleDoctors.svg" alt="medical-doctor"/>
              <FeatureTitle>Expand your referral base</FeatureTitle>
              <FeatureSubText>
                Increase your online presence and attract patients that best match your skill set and expertise.
              </FeatureSubText>
            </FeatureContainer>
            <FeatureContainer>
              <FeatureIcon src="https://storage.googleapis.com/nexos-branding/icons/MachineDoctor.svg" alt="medical-doctor"/>
              <FeatureTitle>Keep your network updated</FeatureTitle>
              <FeatureSubText>
                Instead of waiting days to make changes to your website, simply update your own public profile on Nexos Health within minutes.
              </FeatureSubText>
            </FeatureContainer>
            <FeatureContainer>
              <FeatureIcon src="https://storage.googleapis.com/nexos-branding/icons/NoCost.svg" alt="medical-doctor"/>
              <FeatureTitle>It's Free!</FeatureTitle>
              <FeatureSubText>
                We want every health professional to sign up so their services can be easily found and help those who need them most.
              </FeatureSubText>
            </FeatureContainer>
            <FeatureContainer>
              <FeatureIcon src="https://storage.googleapis.com/nexos-branding/icons/CradledHeart.svg" alt="medical-doctor"/>
              <FeatureTitle>Become a partner</FeatureTitle>
              <FeatureSubText noPadding>
                We are always looking for health professionals to partner with us so that we can help match patients with the best healthcare.
              </FeatureSubText>
              <LinkContainer>
                <LinkButton small to="/#contact-us" onClick={() => clickEvent("contact", {"location": "for-health-professionals"})}>
                  Get in contact
                </LinkButton>
                <LinkArrow icon={["fas", "long-arrow-alt-right"]}/>
              </LinkContainer>
            </FeatureContainer>
          </FeaturesContainer>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper color={LIGHT_COLOR_VARIATION} id="about">
        <ColumnsSection>
          <LeftSection hideMobile>
            <ImageContainer>
              <LargeImage small src="https://storage.googleapis.com/nexos-branding/icons/NexosLogoShortText.svg"/>
            </ImageContainer>
          </LeftSection>
          <RightSection expand>
            <LargeSectionTitle>Our mission and vision</LargeSectionTitle>
            <SectionSubText>
              Nexos Health was created to help connect patients to the best-fit health care for their unique circumstances.
              Nexos envisions a future where the world’s best doctors can be found within the click of a button and where
              anyone can find a great health service that they can afford.
            </SectionSubText>
            <SectionSubText>
              The idea for Nexos Health developed after hearing from GPs within Australia wanting to find the best health
              providers for their patients, but being unable to effectively keep track of who is available and what their
              specialty areas are. Patients also expressed that they felt disempowered, not knowing which health
              professionals were the best-fit to help them.
            </SectionSubText>
            <SectionSubText>
              By helping connect patients to healthcare providers we are on a mission to decrease the
              inefficiencies in healthcare and thereby help people live healthier lives. The faster we can help you
              identify the best health professional for your particular circumstances, the better chance you have of taking
              charge of your health.
            </SectionSubText>
          </RightSection>
        </ColumnsSection>
      </SectionWrapper>
      <SectionWrapper  id="contact-us">
        <ColumnsSection>
          <LeftSection>
            <LargeSectionTitle>Contact Us</LargeSectionTitle>
            <SectionSubText>
              Whether you are a patient looking for assistance, a health professional wanting to find out more, or
              simply would like some questions answered, please email us at &nbsp;<strong>info@nexoshealth.com</strong> or call <strong>0403 863 967</strong>.
            </SectionSubText>
          </LeftSection>
          <RightSection>
            <ImageContainer>
              <LargeImage src="https://storage.googleapis.com/nexos-branding/icons/ContactUs.svg"/>
            </ImageContainer>
          </RightSection>
        </ColumnsSection>
      </SectionWrapper>
    </HomeContainer>
  );
};

export default Home;


const HomeContainer = styled(FlexColumn)`
  align-items: center;
`;

const SectionWrapper = styled(FlexColumn)`
  width: 100%;
  align-items: center;
  padding: 100px 25px;
  ${props => props.color && css`
    background-color: ${props.color};  
  `};
  @media (max-width: 800px) {
    padding: 50px 25px;
    max-width: 600px;
  }
`;

const HeroBackground = styled(FlexColumn)`
  width: 100%;
  align-items: center;
  border-bottom-right-radius: 50%;
  background: rgba(111,66,193,.1);
  padding: 80px 0;
`;

const SingleSection = styled(FlexColumn)`
  max-width: 1000px;
  width: 100%;
  padding-top: ${props => props.padding ? "50px" : "0"};
  
`;

const ColumnsSection = styled(FlexRow)`
  max-width: 1000px;
  width: 100%;
  @media (max-width: 800px) {
    flex-direction: column;  
  }
`;

const LeftSection = styled(FlexColumn)`
  padding-top: 50px;
  align-self: center;
  ${props => props.expand && css`
    flex-grow: 1;
  `};
  ${props => props.hideMobile && css`
    @media (max-width: 800px) {
      display: none;
    }
  `};
  @media (min-width: 800px) {
    flex: ${props => props.expand ? "auto" : "1"};
    padding-right: 45px;
    padding-top: 0;
  }
`;

const RightSection = styled(LeftSection)`
  padding-right: 0;
  ${props => props.oversizedBrowser && css`
    @media (max-width: 800px) {
      overflow: hidden;
    }
  `}
  ${props => props.extraPadding && css`
    padding-top: 60px;
  `}
`

const ImageContainer = styled.div`
  min-width: 200px;
  ${props => props.oversizedBrowser && css`
    width: 180%;
    margin-left: 80px;
    max-width: 1000px;
    @media (max-width: 800px) {
      width: 160%;
      margin-left: 0;
      //content: url("https://storage.googleapis.com/nexos-branding/icons/BrowserProfile.svg")
    }
  `}
`;

const LargeImage = styled.img`
  width: 100%;
  ${props => props.small && css`
    max-width: 200px;  
  `}
`;

const LargeSectionTitle = styled(BoldedText)`
  font-weight: 900;
  font-size: 43px;
  line-height: 100%;
  padding-bottom: 10px;
  color: ${DARK_TEXT_COLOR};
`;

const SectionSubText = styled(ParagraphText)`
  display: inline;
  font-weight: normal;
  font-size: 16px;
  line-height: 29.4px;
  padding-bottom: ${props => props.noPadding ? "0" : "50px"};
  color: ${DARK_TEXT_COLOR};
`;

const SectionHeader = styled(BoldedText)`
  font-weight: bold;
  font-size: 21px;
  line-height: 100%;
  padding-bottom: 10px;
  color: ${PRIMARY_COLOR};
`;

const LinkContainer = styled(FlexRow)`
  padding-bottom: ${props => props.padding ? "35px" : 0};
  color: ${PRIMARY_COLOR};
`;

const ContactDetailsText = styled.div`
  font-size: 14px;
  background-color: lavender;
  padding: 5px 15px;
  border-radius: 5px;
  margin-bottom: 35px;
  margin-top: 10px;
  display: inline;
  color: ${DARK_TEXT_COLOR};
`;

export const LinkButton = styled(HashLink)`
  font-weight: 500;
  color: ${PRIMARY_COLOR};
  font-size: ${props => props.small ? "14px" : "16px"};
  &:hover {
    cursor: pointer;
  }
`;

const LinkArrow = styled(FontAwesomeIcon)`
  color: ${PRIMARY_COLOR};
  align-self: center;
  margin-left: 5px;
  margin-top: 2px;
  &:hover {
    cursor: pointer;
  }
`;

const FeaturesContainer = styled(FlexRow)`
  flex-wrap: wrap;
  justify-content: space-between;
`;

const FeatureContainer = styled(FlexColumn)`
  min-width: 225px;
  max-width: 325px;
  padding-right: 25px;
  flex: 1;
`;

const FeatureIcon = styled.img`
  height: 50px;
  align-self: flex-start;
  padding-bottom: 7px;
`;

const FeatureTitle = styled(BoldedText)`
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: ${DARK_TEXT_COLOR};
`;

const FeatureSubText = styled(ParagraphText)`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  padding-bottom: ${props => props.noPadding ? "0" : "20px"};
  color: #4f4a4a;
`;

const MediumIconImage = styled.img`
  width: 50px;
`;

const ShortThinSeparator = styled(ThinSeparator)`
  max-width: 1000px;
  align-self: center;
  padding: 0 100px;
  @media (max-width: 500px) {
    padding: 0;
  }
`

const SectionContainer = styled(FlexColumn)`
  align-items: center;
`;

const SectionTitle = styled.h2`
  font-weight: bold;
  font-size: ${props => props.medium ? "25px" : "33px"};
  line-height: 100%;
  color: ${DARK_TEXT_COLOR};
`;

const SubText = styled.p`
  text-align: center;
  max-width: 650px;
`;

const FeaturesTitle = styled(Text)`
  font-size: 20px;
  justify-content: center;
  padding-top: 5px;
  font-weight: 800;
  color: #766F6F;
  padding-bottom: 10px;
`;

const FeaturesDescription = styled(Text)`
  justify-content: center;
  padding-bottom: 30px;
`;

const FeaturesIntro = styled.p`
  font-size: 18px;
  padding-bottom: 10px;
  border-bottom: 2px solid #DBE2E9;
`;

const CallToActionContainer = styled(FlexRow)`
  justify-content: flex-start;
`;


