import {useState, useEffect} from "react";


export const useFetch = (url, fetchOptions, deps, hookOptions) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [fetchTimeout, setFetchTimeout] = useState(null);
  const [isInitialRender, setIsInitialRender] = useState(true);


  if (hookOptions === undefined) hookOptions = {};

  function fetchFunc(params=null) {
    if (params) {
      let paramsString = "?";
      for (let [key, value] of Object.entries(params)) {
        paramsString += key + "=" + value
      }
      url = url + paramsString;
    }


    const fetchData = async () => {
      try {
        const res = await fetch(url, fetchOptions);
        const json = await res.json();
        setResponse(json);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);

    if (hookOptions.debounce) {
      if (fetchTimeout) {
        clearTimeout(fetchTimeout);
      }
      const to = setTimeout(() => fetchData(), hookOptions.debounce);
      setFetchTimeout(to);
    } else {
      fetchData();
    }
  }

  useEffect(() => {
    if (hookOptions.runOnInitialRender === false && isInitialRender) {
      setIsInitialRender(false);
      return;
    }
    fetchFunc()
    // eslint-disable-next-line
  }, [url, fetchOptions, ...deps]);
  return [response, loading, error, fetchFunc];
};