import React, {useState} from "react";
import styled, { css } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'react-rainbow-components';


import {BoldedText, FlexColumn, FlexRow, OverflowText, ParagraphText} from "./BaseStyledComponents";
import {clickEvent, selectEvent} from "../utils/amplitude";
import {useAuth} from "../contexts/AuthContext";
import {ThinSeparator} from "./CurrentProfessionalContent";
import verifiedAccount from "../assets/verified-account.png";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {billingOptions, waitTimeOptions} from "../utils/constants";


const InsightContainer = ({connection}) => {
  const [expanded, setExpanded] = useState(true);
  let [clickable, insightsIcon] = getInsightsIcon([connection])

  return (
    <FlexColumn>
      <ConnectionSimple clickable={clickable} onClick={() => setExpanded(!expanded)}>
        {connection.photoURL
          ? <img src={connection.photoURL}/>
          : <SmallProfileIcon icon={["fas", "user-circle"]}/>
        }
        <InsightsName>{connection.firstName} {connection.lastName}</InsightsName>
        <InsightsIconContainer>
          {insightsIcon}
        </InsightsIconContainer>
      </ConnectionSimple>
      {connection.notes && expanded && <InsightCommentText>{connection.notes}</InsightCommentText>}
    </FlexColumn>
  )
}


const InsightsName = styled(OverflowText)`
  color: #191919;
  align-self: center;
  font-weight: 600;
`;

const InsightsIconContainer = styled(FlexColumn)`
  align-self: center;
  padding-left: 5px;
`;

const InsightCommentText = styled(ParagraphText)`
  color: #666666;
  font-size: 13px;
`;

const getInsightsIcon = (connectionsInsights) => {
  let hasNotes = false
  let isStarred = false
  for (let connection of Object.values(connectionsInsights)) {
    isStarred = isStarred || !!connection.isStarred
    hasNotes = hasNotes || !!connection.notes
    if (hasNotes && isStarred) {
      break;
    }
  }

  let clickable = false
  if (hasNotes) {
    clickable = true
    return [clickable, <CommentIcon starred={isStarred} icon={["fas", "comment-dots"]}/>]
  } else {
    return [clickable, <StarIcon clickable={clickable} favoured icon={["fas", "star"]}/>]
  }
}


const InsightsDropdown = ({ connectionsInsights }) => {
  let [clickable, insightsIcon] = getInsightsIcon(Object.values(connectionsInsights))
  return (
    <StyledUncontrolledDropdown nav inNavbar>
      <StyledDropdownToggle nav caret id="profileDropDown">
        {insightsIcon}
      </StyledDropdownToggle>
      <StyledDropdownMenu right>
        {Object.values(connectionsInsights).map((connection) => {
          return (
            <StyledDropdownItem text id="qsProfileBtn">
              <InsightContainer connection={connection}/>
            </StyledDropdownItem>
          )
        })}
      </StyledDropdownMenu>
    </StyledUncontrolledDropdown>
  )
}

const StyledDropdownMenu = styled(DropdownMenu)`
  overflow-y: scroll;
  max-height: 175px;
`;

const getLastUpdatedString = (lastUpdated) => {
  let recentlyUpdated = false
  let lastUpdatedString = "";

  if (lastUpdated) {
    let timestamp = Date.now();
    let lastUpdatedDateTime = new Date(lastUpdated)

    let timeDelta = timestamp - lastUpdatedDateTime.getTime()
    let daysDelta = timeDelta/(1000*60*60*24)

    if (daysDelta < 1) {
      recentlyUpdated = true
      lastUpdatedString = "Today"
    } else if (daysDelta < 2) {
      recentlyUpdated = true
      lastUpdatedString = "Yesterday"
    } else if (daysDelta < 14) {
      recentlyUpdated = true
      lastUpdatedString = `${parseInt(daysDelta)} days ago`
    } else if (daysDelta < 90) {
      recentlyUpdated = true
      lastUpdatedString = `${parseInt(daysDelta/7)} weeks ago`
    }
  }

  return [recentlyUpdated, lastUpdatedString]
}

export const ProfessionalListItem = ({
    professional, professionals, handleCurrentProfessional, favourites, handleFavourProfessional, setSignInModalOpen,
    connectionsInsights
  }) => {

  const { isAuthenticated } = useAuth();
  const favoured = favourites && favourites.professionalsUids.indexOf(professional.uid) >= 0;

  let professionTypesString = professional.professions.map((profession) => {
    return profession.professionType
  }).join(", ")

  let [recentlyUpdated, lastUpdatedString] = getLastUpdatedString(professional.lastUpdated)

  return (
    <ProfessionalListItemMarginWrap>
      <ProfessionalListItemWrapper>
        {/*{selectedProfessionals &&*/}
          {/*<div className="checkbox-selection-container">*/}
            {/*<i className={"fa fa-" + (selectedProfessionals.has(professional.uid) ? "check-square" : "square-o")}*/}
               {/*style={{"font-size": "larger"}}*/}
               {/*onClick={() => handleSelectedProfessional(professional.uid)}*/}
               {/*aria-hidden="true"/>*/}
          {/*</div>*/}
        {/*}*/}
        <ProfessionalInfoContainer>
          <ProfessionalTopLine>
            <ProfessionalHeader>
              <ProfileIcon icon={["fas", "user-circle"]}/>
              <ProfessionalHeaderText>
                <SeparatedLeftHeaderContainer>
                  <LeftHeaderContainer>
                    <ProfessionalName onClick={() => handleCurrentProfessional(professionals, professional)}>
                      {professional.firstName} {professional.lastName}
                    </ProfessionalName>
                    {professional.user && <VerifiedIcon title="Verified Health Professional" src={verifiedAccount} alt="verified-account"/>}
                    <RightArrowIcon icon={["fas", "angle-right"]}/>
                    <LastUpdatedBadge updated={recentlyUpdated} title={`Last Updated ${recentlyUpdated ? lastUpdatedString : "Over 3 Months Ago"}`}>
                      <LastUpdatedBadgeIcon updated={recentlyUpdated} icon={["fas", "history"]}/>
                      {recentlyUpdated ? lastUpdatedString : "Over 3 Months Ago"}
                    </LastUpdatedBadge>
                  </LeftHeaderContainer>
                  {connectionsInsights && Object.keys(connectionsInsights).length &&
                    <ConnectionsFavouritesContainer>
                      <InsightsNumber>{Object.keys(connectionsInsights).length}</InsightsNumber>
                      <InsightsDropdown connectionsInsights={connectionsInsights}/>
                    </ConnectionsFavouritesContainer>
                  }
                </SeparatedLeftHeaderContainer>
                <ProfessionType>{professionTypesString}</ProfessionType>
              </ProfessionalHeaderText>
            </ProfessionalHeader>
          </ProfessionalTopLine>
          <ProfessionalSummary>
            {professional.userNotes && <UserNotes>{professional.userNotes}</UserNotes>}
            <InfoTitle>INTERESTS</InfoTitle>
            <ProfessionalDescription>{professional.description || "-"}</ProfessionalDescription>
            <InfoTitle>ADDITIONAL INFORMATION</InfoTitle>
            <ProfessionalDescription>{professional.additionalInformation || "-"}</ProfessionalDescription>
            <ProfessionalExtrasContainer>
              {!!professional.fees.length && <CostBadge>
                <CostBadgeIcon icon={["fas", "dollar-sign"]}/>
                  {professional.fees[0].amount}
                </CostBadge>
              }
              {((!!professional.minWaitTimeDays && professional.minWaitTimeDays <= 28) || professional.minWaitTimeDays === 0) &&
                <WaitTimeBadge>
                  <WaitTimeBadgeIcon icon={["fas", "clock"]}/>
                  {waitTimeOptions.filter((option) => {
                    return (option.range[0] <= professional.minWaitTimeDays  && professional.minWaitTimeDays <= option.range[1])
                  })[0].label}
                </WaitTimeBadge>
              }
              {billingOptions.map(option => {return option.value}).indexOf(professional.bulkBillingFrequency) >= 0 &&
                <BulkBillBadge>
                  <BulkBillBadgeIcon icon={["fas", "id-card"]}/>
                  {billingOptions.filter((option) => {
                    return option.value === professional.bulkBillingFrequency
                  })[0].label}
                </BulkBillBadge>
              }
              {!!professional.telehealth &&
                <TelehealthBadge>
                  <TelehealthBadgeIcon icon={["fas", "phone"]}/>
                  {"Telehealth"}
                </TelehealthBadge>
              }
            </ProfessionalExtrasContainer>
          </ProfessionalSummary>
        </ProfessionalInfoContainer>
        <ThinSeparator/>
        <FlexRow>
          <StarIcon
            favoured={favoured}
            clickable={true}
            icon={[favoured ? "fas" : "far", "star"]}
            onClick={isAuthenticated ? () => handleFavourProfessional(professional) : () => setSignInModalOpen(true)}/>
          <StarText>{favoured ? "Saved" : "Save"}</StarText>
        </FlexRow>
      </ProfessionalListItemWrapper>
    </ProfessionalListItemMarginWrap>
  )
};


const ProfessionalListItemWrapper = styled(FlexColumn)`
  width: 100%;
  justify-content: space-between;
  list-style-position: inside;
  border: 1px solid #f5e8e8;
  border-radius: 5px;
  padding: 0 1.2em 0.5em;
`;

const ProfessionalListItemMarginWrap = styled(FlexColumn)`
  margin-top: 1em;
  border-radius: 5px;
  box-shadow: 0 3px 7px 0 #e8e5df;
`;

const ProfessionalInfoContainer = styled(FlexColumn)`
  justify-content: space-around;
  flex-grow: 2;
  width: 100%;
  padding: 0 0 10px 0;
`;

export const StarIcon = styled(FontAwesomeIcon)`
  margin-right: 0.2em;
  align-self: center;
  ${props => props.clickable && css`
    &:hover {
      cursor: pointer
    }
  `}
  ${props => props.favoured && css`
    color: gold;
  `}
`;

const CommentIcon = styled(FontAwesomeIcon)`
  margin-right: 0.2em;
  align-self: center;
  color: #3b6ad3;
  &:hover {
    cursor: pointer
  }
  ${props => props.starred && css`
    color: gold;
  `}
`;

const ProfessionalTopLine = styled(FlexRow)`
  padding: 10px 0 0 0;
`;

export const ProfessionalHeader = styled(FlexRow)`
  width: 100%;
  height: 100%;
`;

export const ProfessionalHeaderText = styled(FlexColumn)`
  width: 100%;
  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
  };
`;

export const ProfileIcon = styled(FontAwesomeIcon)`
  align-self: center;
  font-size: 2.7em;
  margin-right: 0.3em;
  color: cornflowerblue;
`;

export const SmallProfileIcon = styled(ProfileIcon)`
  font-size: 25px;
`;

const LeftHeaderContainer = styled(FlexRow)`
  &:hover {
    color: royalblue;
  }
`;

const SeparatedLeftHeaderContainer = styled(FlexRow)`
  justify-content: space-between;
  flex-grow: 2;
`;

const InsightsNumber = styled(BoldedText)`
  font-weight: 700;
  align-self: flex-start;
  padding-top: 3px;
`;

const ConnectionsFavouritesContainer = styled(FlexRow)`
  margin-top: -4px;
`;

const ConnectionSimple = styled(FlexRow)`
  padding-bottom: 3px;
  ${props => props.clickable && css`
    &:hover {
      cursor: pointer;
    }  
  `}
`;

export const StyledDropdownToggle = styled(DropdownToggle)`
  padding: 0 0 0 4px;
`

export const StyledUncontrolledDropdown = styled(UncontrolledDropdown)`
  list-style: none;
  max-height: 25px;
`

const StyledDropdownItem = styled(DropdownItem)`
  font-size: 14px;
  padding: 4px 10px;
`;

export const ProfessionalName = styled(OverflowText)`
  color: black;
  font-size: 1em;
  font-weight: 700;
  white-space: normal;
  line-height: 20px;
  &:hover {
    cursor: pointer;
  }
`;

export const VerifiedIcon = styled.img`
  align-self: flex-start;
  margin: 0 0 0 3px;
  width: 20px;
`;

export const RightArrowIcon = styled(FontAwesomeIcon)`
  align-self: flex-start;
  margin: 2px 0 0 5px;
  color: #191919;
`;

const LastUpdatedBadge = styled(Badge)`
  background-color: #ffc107f0;
  margin: 2px 0 0 8px;
  padding: 2px 8px 2px 0;
  font-size: 10px;
  overflow: visible;
  color: #ffffff;
  font-weight: 600;
  height: 16px;
  ${props => !props.updated && css`
    background-color: #f1f0f0;
    color: black;
  `}
`;

const LastUpdatedBadgeIcon = styled(FontAwesomeIcon)`
  margin: 0 5px 0 7px;
  color: #ffffff;
  ${props => !props.updated && css`
    color: black;
  `}
`;

const CostBadgeIcon = styled(FontAwesomeIcon)`
  margin: 0 5px 0 7px;
  color: #3593b8;
`;

const WaitTimeBadgeIcon = styled(CostBadgeIcon)`
  color: #B35A03;
`;

const BulkBillBadgeIcon = styled(CostBadgeIcon)`
  color: #4039A8;
`;

const TelehealthBadgeIcon = styled(CostBadgeIcon)`
  color: #1e66d6;
`;

const CostBadge = styled(Badge)`
  background-color: #E1FAF3;
  padding: 1px 7px 1px 0;
  margin: 5px 0;
  font-size: 11px;
  overflow: visible;
  color: #215A37;
  font-weight: 600;
`;

const WaitTimeBadge = styled(CostBadge)`
  background-color: #FEF9E7;
  color: #B35A03;
`;

const BulkBillBadge = styled(CostBadge)`
  background-color: #F8F1FF;
  color: #4039A8;
`;

const TelehealthBadge = styled(CostBadge)`
  background-color: #7891eb4f;
  color: #1e66d6;
`;

const ProfessionalSummary = styled(FlexColumn)`
`;

const UserNotes = styled(OverflowText)`
`;

const InfoTitle = styled(BoldedText)`
  color: #191919;
  font-size: 10px;
  padding-top: 3px;
`;


const ProfessionalExtrasContainer = styled(FlexRow)`
  padding-top: 5px;
  flex-wrap: wrap;
`;

const ExtrasFieldTypeContainer = styled(FlexColumn)`
  padding: 0 1em 0 0;
`;

const ExtrasFieldTextContainer = styled(FlexColumn)`
  overflow: hidden;
`;

const ExtrasFieldHeading = styled(OverflowText)`
  color: #191919;
  font-weight: 600;
  font-size: 13px;
`;

const ExtrasFieldText = styled(OverflowText)`
  color: #191919;
  font-size: 13px;
  min-height: 22px;
`;


const ProfessionalDescription = styled(OverflowText)`
  flex-grow: 4;
  max-width: 100%;
  color: #313336;
  font-size: 13px;
  margin-top: -5px;
`;


export const ProfessionType = styled(OverflowText)`
  color: black;
  font-size: 12px;
  max-width: 200px;
  align-self: flex-start;
  @media (min-width: 600px) {
    padding: 0 0 0 15px;
    font-weight: 600;
  }
`;

export const StarText = styled(BoldedText)`
  align-items: center;
`;
