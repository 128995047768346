export const FETCH_PROFESSION_TYPES = "FETCH_PROFESSION_TYPES";
export const UPDATE_PROFESSION_TYPES = "UPDATE_PROFESSION_TYPES";
export const FETCH_PROFESSIONALS = "FETCH_PROFESSIONALS";
export const FETCH_MORE_PROFESSIONALS = "FETCH_MORE_PROFESSIONALS";
export const UPDATE_PROFESSIONALS = "UPDATE_PROFESSIONALS";
export const UPDATE_MORE_PROFESSIONALS = "UPDATE_MORE_PROFESSIONALS";
export const FETCH_PROFESSIONAL = "FETCH_PROFESSIONAL";
export const FETCH_PROFILE = "FETCH_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const UPDATE_PROFESSIONAL = "UPDATE_PROFESSIONAL";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFESSIONAL_NOTES = "UPDATE_PROFESSIONAL_NOTES";
export const ADD_PROFESSIONALS_TO_GROUP = "ADD_PROFESSIONALS_TO_GROUP";
export const REMOVE_PROFESSIONALS_FROM_GROUP = "REMOVE_PROFESSIONALS_FROM_GROUP";
export const CREATE_GROUP = "CREATE_GROUP";
export const FETCH_GROUPS = "FETCH_GROUPS";
export const FETCH_MEDICAL_LOCATIONS = "FETCH_MEDICAL_LOCATIONS";
export const REMOVE_MEDICAL_LOCATION = "REMOVE_MEDICAL_LOCATION";
export const FETCH_FAVOURITES = "FETCH_FAVOURITES";
export const FAVOUR_PROFESSIONAL = "FAVOUR_PROFESSIONAL";
export const UNFAVOUR_PROFESSIONAL = "UNFAVOUR_PROFESSIONAL";
export const SUBMIT_CORRECTION = "SUBMIT_CORRECTION";
export const CORRECTION_SUBMITTED = "CORRECTION_SUBMITTED";
export const EDIT_PROFESSIONAL_NOTE = "EDIT_PROFESSIONAL_NOTE";
export const EDIT_PROFILE_INFO = "EDIT_PROFILE_INFO";
export const DELETE_GROUP = "DELETE_GROUP";
export const EDIT_GROUP = "EDIT_GROUP";
export const UPDATE_GROUPS = "UPDATE_GROUPS";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_MEDICAL_LOCATIONS = "UPDATE_MEDICAL_LOCATIONS";
export const UPDATE_EDITED_GROUP = "UPDATE_EDITED_GROUP";
export const CREATE_CONNECTION = "CREATE_CONNECTION"
export const DELETE_CONNECTION = "DELETE_CONNECTION"
export const ACCEPT_CONNECTION = "ACCEPT_CONNECTION"
export const FETCH_CONNECTIONS = "FETCH_CONNECTIONS"
export const FETCH_CONNECTIONS_INSIGHTS = "FETCH_CONNECTIONS_INSIGHTS"
export const UPDATE_DELETED_CONNECTION = "UPDATE_DELETED_CONNECTION"
export const UPDATE_CONNECTION = "UPDATE_CONNECTION"
export const UPDATE_CONNECTIONS = "UPDATE_CONNECTIONS"
export const UPDATE_CONNECTIONS_INSIGHTS = "UPDATE_CONNECTIONS_INSIGHTS"


export function clearAccount() {
  return {
    type: "RESET_STORE",
  }
}

export function fetchProfessionTypes() {
  return {
    type: FETCH_PROFESSION_TYPES,
    meta: {
      method: 'GET',
      endpoint: `/api/professionals/profession-types/`,
      success: updateProfessionTypes,
    }
  }
}

function updateProfessionTypes(professionTypes) {
  let orderedProfessionTypes = professionTypes.data.sort((a, b) => {
      const aName = a.name;
      const bName = b.name;
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
  })
  return {
    type: UPDATE_PROFESSION_TYPES,
    payload: {
      professionTypes: orderedProfessionTypes
    }
  }
}

const getParamsString = (queryText, professionTypes, location, distance, billingType, maxWaitTime, maxFeeAmount, offset) => {
  let paramsString = "?"
  if (queryText) {
    paramsString += `q=${queryText}&`
  }
  if (professionTypes && professionTypes.length) {
    let professionTypeIds = professionTypes.map((professionType) => {
      return (professionType.value)
    });
    paramsString += `professionTypes=${professionTypeIds.join(",")}&`
  }
  if (location && distance) {
    paramsString += `location=${location}&distance=${distance}&`
  }
  if (billingType) {
    paramsString += `billingType=${billingType}&`
  }
  if (maxWaitTime) {
    paramsString += `maxWaitTime=${maxWaitTime}&`
  }
  if (maxFeeAmount) {
    paramsString += `maxFeeAmount=${maxFeeAmount}&`
  }
  if (offset) {
    paramsString += `offset=${offset}`
  }

  return paramsString
}

export function fetchProfessionals(queryText="", professionTypes=null, location=null, distance=null, billingType=null, maxWaitTime=null, maxFeeAmount=null) {
  let paramsString = getParamsString(queryText, professionTypes, location, distance, billingType, maxWaitTime, maxFeeAmount)
  return {
    type: FETCH_PROFESSIONALS,
    meta: {
      method: 'GET',
      endpoint: `/api/professionals/search${paramsString}`,
      success: updateProfessionals,
    }
  }
}

export function fetchMoreProfessionals(queryText="", professionTypes=null, location=null, distance=null, billingType=null, maxWaitTime=null, maxFeeAmount=null, offset=null) {
  let paramsString = getParamsString(queryText, professionTypes, location, distance, billingType, maxWaitTime, maxFeeAmount, offset)
  return {
    type: FETCH_MORE_PROFESSIONALS,
    meta: {
      method: 'GET',
      endpoint: `/api/professionals/search${paramsString}`,
      success: updateMoreProfessionals,
    }
  }
}

function updateProfessionals(resp) {
  return {
    type: UPDATE_PROFESSIONALS,
    payload: {
      professionals: resp.data.results,
      offset: resp.data.newOffset,
      count: resp.data.count
    }
  }
}

function updateMoreProfessionals(resp) {
  return {
    type: UPDATE_MORE_PROFESSIONALS,
    payload: {
      professionals: resp.data.results,
      offset: resp.data.newOffset,
      count: resp.data.count
    }
  }
}

export function addProfessionalsToGroup(professionals, group) {
  let professionalsList = [...professionals];
  return {
    type: ADD_PROFESSIONALS_TO_GROUP,
    meta: {
      method: 'POST',
      endpoint: "/api/groups/add_professionals/",
      body: {
        group: group,
        professionals: professionalsList
      },
      success: updateGroup,
    }
  }
}

export function removeProfessionalsFromGroup(professionals, group) {
  let professionalsList = [...professionals];
  return {
    type: REMOVE_PROFESSIONALS_FROM_GROUP,
    meta: {
      method: 'DELETE',
      endpoint: "/api/groups/remove_professionals/",
      body: {
        group: group,
        professionals: professionalsList
      },
      success: updateGroup,
    }
  }
}

export function favourProfessional(professional, group) {
  let professionalsList = [professional];
  return {
    type: FAVOUR_PROFESSIONAL,
    meta: {
      method: 'POST',
      endpoint: "/api/groups/add_professionals/",
      body: {
        group: group,
        professionals: professionalsList
      },
      success: updateGroup,
    }
  }
}

export function unfavourProfessional(professional, group) {
  let professionalsList = [professional];
  return {
    type: UNFAVOUR_PROFESSIONAL,
    meta: {
      method: 'DELETE',
      endpoint: "/api/groups/remove_professionals/",
      body: {
        group: group,
        professionals: professionalsList
      },
      success: updateGroup,
    }
  }
}

export function submitCorrection(professionalUid, firstName, lastName, correction) {
  return {
    type: SUBMIT_CORRECTION,
    meta: {
      method: 'POST',
      endpoint: "/api/users/submit-correction/",
      body: {
        correction: correction,
        professional: professionalUid,
        firstName: firstName,
        lastName: lastName,
      },
      success: correctionSubmitted,
    }
  }
}


export function correctionSubmitted() {
  return {
    type: CORRECTION_SUBMITTED,
  }
}

export function editProfessionalNotes(professional, notes) {
  return {
    type: EDIT_PROFESSIONAL_NOTE,
    meta: {
      method: 'POST',
      endpoint: "/api/users/create_note/",
      body: {
        notes: notes,
        professional: professional
      },
      success: updateProfessionalNotes,
    }
  }
}

export function editProfileInfo(uid, data) {
  return {
    type: EDIT_PROFILE_INFO,
    meta: {
      method: 'PUT',
      endpoint: `/api/professionals/profile/edit?uid=${uid}`,
      body: data,
      success: updateProfessional,
    }
  }
}

export function removeMedicalLocation(uid, locationId) {
  return {
    type: REMOVE_MEDICAL_LOCATION,
    meta: {
      method: 'DELETE',
      endpoint: `/api/professionals/profile/role?uid=${uid}`,
      body: {
        "location": locationId
      },
      success: updateProfessional,
    }
  }
}

export function fetchProfessional(uid) {
  return {
    type: FETCH_PROFESSIONAL,
    meta: {
      method: 'GET',
      endpoint: `/api/professionals/get_professional/?uid=${uid}`,
      success: updateProfessional,
    }
  }
}

export function fetchProfile() {
  return {
    type: FETCH_PROFILE,
    meta: {
      method: 'GET',
      endpoint: `/api/professionals/profile`,
      success: updateProfile,
    }
  }
}

export function clearProfile() {
  return {
    type: CLEAR_PROFILE,
  }
}

function updateProfessionalNotes(data) {
  return {
    type: UPDATE_PROFESSIONAL_NOTES,
    payload: {
      professionalUid: data.professionalUid,
      notes: data.notes
    }
  }
}

function updateProfessional(data) {
  return {
    type: UPDATE_PROFESSIONAL,
    payload: {
      professional: data,
    }
  }
}

function updateProfile(profile) {
  return {
    type: UPDATE_PROFILE,
    payload: {
      profile: profile.data,
    }
  }
}

export function fetchGroups() {
  return {
    type: FETCH_GROUPS,
    meta: {
      method: 'GET',
      endpoint: "/api/groups/",
      success: updateGroups,
    }
  }
}

export function fetchMedicalLocations(searchTerm) {
  return {
    type: FETCH_MEDICAL_LOCATIONS,
    meta: {
      method: 'GET',
      endpoint: `/api/medical-locations/search/?q=${searchTerm}`,
      success: updateMedicalLocations,
    }
  }
}

export function fetchFavourites() {
  return {
    type: FETCH_FAVOURITES,
    meta: {
      method: 'GET',
      endpoint: "/api/groups/get_favourites/",
      success: updateGroups,
    }
  }
}

export function createConnection(connectedProfessional) {
  return {
    type: CREATE_CONNECTION,
    meta: {
      method: 'POST',
      body: {
        connectedProfessional
      },
      endpoint: `/api/connections/`,
      success: updateConnection,
    }
  }
}

export function deleteConnection(connectionId) {
  return {
    type: DELETE_CONNECTION,
    meta: {
      method: 'DELETE',
      endpoint: `/api/connections/${connectionId}/`,
      success: updateDeletedConnection,
    }
  }
}

export function acceptConnection(connectionId) {
  return {
    type: ACCEPT_CONNECTION,
    meta: {
      method: 'PUT',
      body: {
        id: connectionId
      },
      endpoint: `/api/connections/${connectionId}/`,
      success: updateConnection,
    }
  }
}

export function fetchConnections() {
  return {
    type: FETCH_CONNECTIONS,
    meta: {
      method: 'GET',
      endpoint: "/api/connections/",
      success: updateConnections,
    }
  }
}

export function fetchConnectionsInsights() {
  return {
    type: FETCH_CONNECTIONS_INSIGHTS,
    meta: {
      method: 'GET',
      endpoint: "/api/connections/insights/",
      success: updateConnectionsInsights,
    }
  }
}

export function updateDeletedConnection(data) {
  return {
    type: UPDATE_DELETED_CONNECTION,
    payload: {
      connectionId: data.id,
    }
  }
}

export function updateConnection(data) {
  return {
    type: UPDATE_CONNECTION,
    payload: {
      connectionId: data.id,
      connection: data
    }
  }
}

const formatConnections = (connections) => {
  let formattedConnections = {}
  connections.forEach((connection) => {
    formattedConnections[connection.id] = connection
  })
  return formattedConnections
}


export function updateConnections(data) {
  let formattedConnections = formatConnections(data)
  return {
    type: UPDATE_CONNECTIONS,
    payload: {
      connections: formattedConnections
    }
  }
}

export function updateConnectionsInsights(data) {
  return {
    type: UPDATE_CONNECTIONS_INSIGHTS,
    payload: {
      insights: data
    }
  }
}


export function createGroup(name, description) {
  return {
    type: CREATE_GROUP,
    meta: {
      method: 'POST',
      endpoint: "/api/groups/",
      body: {
        name: name,
        description: description
      },
      success: updateGroup,
    }
  }
}

export function deleteGroup(value) {
  return {
    type: DELETE_GROUP,
    meta: {
      method: 'DELETE',
      endpoint: "/api/groups/delete_group/",
      body: {
        group: value
      },
      success: updateGroup,
    }
  }
}

export function editGroup(value, name, description) {
  return {
    type: EDIT_GROUP,
    meta: {
      method: 'PUT',
      endpoint: "/api/groups/edit_group/",
      body: {
        group: value,
        name: name,
        description: description
      },
      success: updateEditedGroup,
    }
  }
}


export function updateGroups(data) {
  return {
    type: UPDATE_GROUPS,
    payload: {
      groups: data
    }
  }
}

function updateGroup(data) {
  return {
    type: UPDATE_GROUP,
    payload: {
      groupUid: Object.keys(data).pop(),
      groupDetails: Object.values(data).pop()
    }
  }
}

function updateMedicalLocations(data) {
  return {
    type: UPDATE_MEDICAL_LOCATIONS,
    payload: {
      medicalLocations: data
    }
  }
}

function updateEditedGroup(data) {
  return {
    type: UPDATE_EDITED_GROUP,
    payload: {
      groupUid: data["uid"],
      groupName: data["name"],
      groupDescription: data["description"]
    }
  }
}
