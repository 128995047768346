import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import TagManager from 'react-gtm-module';

// FontAwesome Imports
import initFontAwesome from "./utils/initFontAwesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {far} from "@fortawesome/free-regular-svg-icons";
import {fas} from "@fortawesome/free-solid-svg-icons";

import './index.css';
import * as serviceWorker from './serviceWorker';
import configureStore from "./redux/configureStore";
import history from "./utils/history";
import {Router} from "react-router-dom";
import {AuthProvider} from "./contexts/AuthContext";
import App from "./App";

export const store = configureStore({});


const rootElement = document.getElementById('root');

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

// Initialise Google Tag Manager
if (process.env.REACT_APP_ENV === "production") {
  const tagManagerArgs = {
    gtmId: 'GTM-MV98J6B'
  };
  TagManager.initialize(tagManagerArgs);
} else if (process.env.REACT_APP_ENV === "development") {
  const tagManagerArgs = {
    gtmId: 'GTM-K9NLXDV'
  };
  TagManager.initialize(tagManagerArgs);
}

// Initialise Amplitude
const amplitude = require('amplitude-js');
amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);

// Initialising Font Awesome
initFontAwesome();
library.add(far, fas);

// Application to render
ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>
      <Router history={history}>
        <App/>
      </Router>
    </AuthProvider>
  </Provider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
