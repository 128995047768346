import React from 'react';
import styled, {css} from 'styled-components/macro';

import {
  BoldedText,
  FlexColumn,
  FlexRow, ParagraphText,
  Text
} from "../../components/BaseStyledComponents";
import {ThinSeparator} from "../../components/CurrentProfessionalContent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DARK_TEXT_COLOR, LIGHT_COLOR_VARIATION, PRIMARY_COLOR} from "../../utils/constants";
import {HashLink} from "react-router-hash-link";


const PrivacyPolicyPage = () => {

  return (
    <Container>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>Privacy Policy</SectionTitle>
          <SectionSubText>
            This privacy policy sets out how Nexos Health (ABN 46 603 121 960) collects, processes and uses your Personal Information through your use of our Services.
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>1 - Definitions</SectionTitle>
          <SectionSubText>
            <strong>Anonymised Data</strong> means anonymous information collected by us through your use of this Website or our Services for statistical and/or demographic purposes. Anonymised Data may be derived from Personal Information.
            <br/>
            <strong>Data Processor</strong> means each of the following: Google Cloud.
            <br/>
            <strong>DPO</strong> means Data Protection Officer, and is the person specifically appointed by us to deal with
            your enquiries with respect to this privacy policy and your Personal Information.
            <br/>
            <strong>Personal Information</strong> means any identifying information about you. This includes, but is not limited to, the following: email address; name; username/passwords; and cookies and usage data.
            <br/>
            <strong>Services</strong> means all services provided by Nexos Health to you, including (but not limited to) this Website and the following: Delivery of product and service; Marketing and communications; and User management.
            <br/>
            <strong>Website</strong> means https://nexoshealth.com.
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>2 - Personal Information Collection</SectionTitle>
          <SectionSubText>
            We only collect and use Personal Information to the extent necessary to provide you with the Services. We collect Personal Information for the Website to provide you with a better online experience.
            We also provide the following services:
            <br/>
            <strong>Delivery of product and service</strong>
            <br/>
            To deliver the Services, fulfill and manage customer orders and provide customer support.
            <br/>
            <strong>Marketing and communications</strong>
            <br/>
            To send marketing and promotional materials and other information that may be of interest to you.
            <br/>
            <strong>User management</strong>
            <br/>
            To allow users to create and manage their accounts so they can use the Services.
            <br/>
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>3 - Personal Information Retention</SectionTitle>
          <SectionSubText>
            We keep Personal Information for the shortest time necessary to provide you with Services and to meet all
            our legal and compliance obligations. To determine this retention period, we take into account
            (i) the nature of the Personal Information gathered; and
            (ii) the nature of our legal and compliance obligations. All Personal Information no longer required by us is destroyed and/or erased.
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>4 - Anonymised Data</SectionTitle>
          <SectionSubText>
            We may use, collect and share Anonymised Data for any purpose at our discretion. Anonymised Data cannot be used to reveal your identity (either directly or indirectly). We may retain all Anonymised Data indefinitely.
            <br/>
            Anonymised Data includes:
            <br/>
            • Device data: This includes information about the device used to interact with our Services.
            <br/>
            • Location data: This includes geolocation data (based on your IP address).
            <br/>
            • Log and usage data: This includes usage and performance information with respect to our Services.
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>5 - Personal Data from Third Parties</SectionTitle>
          <SectionSubText>
            We may, from time to time, obtain Personal Information from third parties to enable us to better tailor our
            Services to you (<strong>Third Party Personal Information</strong>). When we obtain Third Party Personal Information, we
            will notify you within one month.
            <br/>
            If we use or share Third Party Personal Information, we will notify you immediately.
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>6 - Your Rights</SectionTitle>
          <SectionSubText>
            With respect to Personal Information we hold, you have the following rights:<br/>
            • <strong>Access:</strong> You may request from us access to your data that we hold on you.<br/>
            • <strong>Rectification:</strong> If the data we hold on you is inaccurate, you may request that we correct it. If the data we hold is incomplete, you may request that we complete it.<br/>
            • <strong>Erasure:</strong> Subject to certain conditions, you may request that we erase all of the data we hold on you.<br/>
            • <strong>Restrictions:</strong> Subject to certain conditions, you may request that we restrict the processing of data we hold on you.<br/>
            • <strong>Portability:</strong> Subject to certain conditions, you may request that we transfer all the data we hold on you to a third party (including yourself).<br/>
            • <strong>Objections:</strong> Subject to certain conditions, you may object to us processing the data we hold on you.<br/>
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>7 - Data Processors</SectionTitle>
          <SectionSubText>
            We engage Data Processors to assist us in providing you with Services. Data Processors are
            contractually obligated to us, and must:<br/>
            • comply with, and act only on, our instructions with respect to the data that they process on our behalf (we, of course, give our instructions in accordance with this privacy policy);<br/>
            • notify us if they believe we are acting in breach of the GDPR (where applicable);<br/>
            • first obtain our consent prior to sub-contracting data processing services;<br/>
            • comply with their obligations under the GDPR (where applicable).<br/>
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>8 - Your Account</SectionTitle>
          <SectionSubText>
            If you choose to create an online account with us, you have the right to:<br/>
            • Update or delete your account at any time; and<br/>
            • Opt-out of receiving emails and other communications from us.
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>9 - Public Posts</SectionTitle>
          <SectionSubText>
            You may choose to engage with our Services by posting or sharing your own content (including Personal Information) on our Website. If you choose to voluntarily share Personal Information on a publicly available forum, we cannot prevent the further use of your Personal Information.
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>10 - Social Media</SectionTitle>
          <SectionSubText>
            You may choose to engage with our Services through a variety of social media sites and platforms, for example Facebook, Instagram and Twitter. When you interact with our Services through these social media platforms, you may allow us to receive Personal Information and other Anonymised Data on how you are interacting with our Services. This will depend on your privacy settings on each social media site and platform.
            <br/>
            <br/>
            The data we are provided by you through your interaction with our Services on social media sites and platforms will be used by us subject to the terms of this privacy policy. Depending on your social media site’s privacy settings, your Personal Information may become public to a community of users if you choose to engage with our Services through these platforms.
            <br/>
            <br/>
            For further information on how you can customize your privacy settings on social media sites and how those sites handle your privacy, please refer to the privacy policy of each particular social media site.
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>11 - Minors</SectionTitle>
          <SectionSubText>
            We do not knowingly collect information on children under the age of 16 (<strong>"Child"</strong>). If you become aware that a Child has provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from a Child without their parent's verifiable authorization to access our Services, we take steps to remove that information from our servers.
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>12 - Third Party Links</SectionTitle>
          <SectionSubText>
            Our website contains links to other third-party websites not owned or managed by Nexos Health. This privacy policy applies to this website only. If you click a link to a third-party website, the privacy policy of that website will apply. We highly recommend that you read the privacy policies of other websites as they may be different from ours.
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>13 - Payment Vendors</SectionTitle>
          <SectionSubText>
            You can make payments via our website. Our vendors are listed below:
            <br/>
            <strong>Stripe:</strong>
            <br/>
            Their privacy policy can be viewed at https://stripe.com/privacy.
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>14 - Merger, acquisition or asset sale</SectionTitle>
          <SectionSubText>
            If we or our subsidiaries are involved in a merger, acquisition, or asset sale, your Personal Information
            may be transferred.
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>15 - Updates</SectionTitle>
          <SectionSubText>
            We are constantly reviewing our privacy policy and procedures to ensure that they meet best
            practices. This policy was last updated on 01/04/2021.
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>16 - Contact us</SectionTitle>
          <SectionSubText>
            If you have any questions or suggestions about our privacy policy or want to know more information about Personal Information we hold, please contact us at info@nexoshealth.com or write to us at 1/28-30 William St, Elwood.
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
      <SectionWrapper>
        <SingleSection>
          <SectionTitle>17 - Complaints</SectionTitle>
          <SectionSubText>
            If you believe that we have breached our obligations to you under this privacy policy or relevant privacy laws, please contact us at info@nexoshealth.com or write to us at 1/28-30 William St, Elwood.
            <br/>
            If you are not satisfied with our response, you have the right to lodge a complaint at any time to your relevant authority.
          </SectionSubText>
        </SingleSection>
      </SectionWrapper>
    </Container>
  );
};

export default PrivacyPolicyPage;


const Container = styled(FlexColumn)`
  align-items: center;
  padding: 100px 0;
`;

const SectionWrapper = styled(FlexColumn)`
  width: 100%;
  align-items: center;
`;

const HeroBackground = styled(FlexColumn)`
  width: 100%;
  align-items: center;
  border-bottom-right-radius: 50%;
  background: rgba(111,66,193,.1);
  padding: 80px 0;
`;

const SingleSection = styled(FlexColumn)`
  max-width: 1000px;
  width: 100%;
  padding: 0 50px;
`;

const ColumnsSection = styled(FlexRow)`
  max-width: 1000px;
  width: 100%;
  @media (max-width: 800px) {
    flex-direction: column;  
  }
`;

const LeftSection = styled(FlexColumn)`
  padding-top: 50px;
  align-self: center;
  ${props => props.expand && css`
    flex-grow: 1;
  `};
  ${props => props.hideMobile && css`
    @media (max-width: 800px) {
      display: none;
    }
  `};
  @media (min-width: 800px) {
    flex: ${props => props.expand ? "auto" : "1"};
    padding-right: 45px;
    padding-top: 0;
  }
`;

const RightSection = styled(LeftSection)`
  padding-right: 0;
  ${props => props.oversizedBrowser && css`
    @media (max-width: 800px) {
      overflow: hidden;
    }
  `}
`

const ImageContainer = styled.div`
  min-width: 200px;
  ${props => props.oversizedBrowser && css`
    width: 180%;
    margin-left: 80px;
    max-width: 1000px;
    @media (max-width: 800px) {
      width: 160%;
      margin-left: 0;
      //content: url("https://storage.googleapis.com/nexos-branding/icons/BrowserProfile.svg")
    }
  `}
`;

const LargeImage = styled.img`
  width: 100%;
  ${props => props.small && css`
    max-width: 200px;  
  `}
`;

const LargeSectionTitle = styled(BoldedText)`
  font-weight: 900;
  font-size: 43px;
  line-height: 100%;
  padding-bottom: 10px;
  color: ${DARK_TEXT_COLOR};
`;

const SectionSubText = styled(ParagraphText)`
  display: inline;
  font-weight: normal;
  font-size: 16px;
  line-height: 29.4px;
  padding-bottom: ${props => props.lessPadding ? "10px" : "50px"};
  color: ${DARK_TEXT_COLOR};
`;

const SectionHeader = styled(BoldedText)`
  font-weight: bold;
  font-size: 21px;
  line-height: 100%;
  padding-bottom: 10px;
  color: ${PRIMARY_COLOR};
`;

const LinkContainer = styled(FlexRow)`
  padding-bottom: ${props => props.padding ? "35px" : 0};
  color: ${PRIMARY_COLOR};
`;

const LinkButton = styled(HashLink)`
  font-weight: 500;
  color: ${PRIMARY_COLOR};
  font-size: ${props => props.small ? "14px" : "16px"};
  &:hover {
    cursor: pointer;
  }
`;

const LinkArrow = styled(FontAwesomeIcon)`
  color: ${PRIMARY_COLOR};
  align-self: center;
  margin-left: 5px;
  margin-top: 2px;
  &:hover {
    cursor: pointer;
  }
`;

const FeaturesContainer = styled(FlexRow)`
  flex-wrap: wrap;
  justify-content: space-between;
`;

const FeatureContainer = styled(FlexColumn)`
  min-width: 225px;
  max-width: 325px;
  padding-right: 25px;
  flex: 1;
`;

const FeatureIcon = styled.img`
  height: 50px;
  align-self: flex-start;
  padding-bottom: 7px;
`;

const FeatureTitle = styled(BoldedText)`
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: ${DARK_TEXT_COLOR};
`;

const FeatureSubText = styled(ParagraphText)`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  padding-bottom: ${props => props.noPadding ? "0" : "20px"};
  color: #4f4a4a;
`;

const MediumIconImage = styled.img`
  width: 50px;
`;

const ShortThinSeparator = styled(ThinSeparator)`
  max-width: 1000px;
  align-self: center;
  padding: 0 100px;
`

const SectionContainer = styled(FlexColumn)`
  align-items: center;
`;

const SectionTitle = styled.h2`
  align-self: flex-start; 
  font-weight: bold;
  font-size: 25px;
  line-height: 100%;
  color: ${DARK_TEXT_COLOR};
`;

const SubText = styled.p`
  text-align: center;
  max-width: 650px;
`;

const FeaturesTitle = styled(Text)`
  font-size: 20px;
  justify-content: center;
  padding-top: 5px;
  font-weight: 800;
  color: #766F6F;
  padding-bottom: 10px;
`;

const FeaturesDescription = styled(Text)`
  justify-content: center;
  padding-bottom: 30px;
`;

const FeaturesIntro = styled.p`
  font-size: 18px;
  padding-bottom: 10px;
  border-bottom: 2px solid #DBE2E9;
`;

const CallToActionContainer = styled(FlexRow)`
  justify-content: flex-start;
`;


