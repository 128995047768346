import React from "react";
import styled from 'styled-components/macro';

import {
  BaseButton,
  FlexColumn,
  FlexRow,
} from "./BaseStyledComponents";
import {useAuth} from "../contexts/AuthContext";
import {
  StarIcon,
  StarText
} from "./ProfessionalListItem";
import {ProfileView} from "./Profile/ProfileView";
import Loading from "./Loading";



export const CurrentProfessionalContent = ({
    currentProfessional, favourites, setSignInModalOpen=null, handleFavourProfessional, handleClose
  }) => {

  const { isAuthenticated } = useAuth();
  const favoured = favourites && favourites.professionalsUids.indexOf(currentProfessional && currentProfessional.uid) >= 0;

  if (!currentProfessional) {
    return <Loading fullScreen={false}/>
  }

  return (
    <CurrentProfessionalContainer>
      <ProfileView
        professional={currentProfessional}
        favourites={favourites}
        setSignInModalOpen={setSignInModalOpen}
      />
      <ActionsContainer>
        <StarContainer>
          <StarIcon
            favoured={favoured}
            clickable={true}
            icon={[favoured ? "fas" : "far", "star"]}
            onClick={isAuthenticated ? () => handleFavourProfessional(currentProfessional) : () => setSignInModalOpen(true)}/>
          <StarText>{favoured ? "Saved" : "Save"}</StarText>
        </StarContainer>
        <BaseButton onClick={handleClose}>Close</BaseButton>
      </ActionsContainer>
    </CurrentProfessionalContainer>
  )
};


const CurrentProfessionalContainer = styled(FlexColumn)`
  all: unset; // react-modal seems to be making all children elements inherit their parents attributes (e.g. height)
  height: auto;
  justify-content: flex-start;
  overflow: auto;
;`

const Separator = styled.hr`
  background-color: #efedea;
  border: none;
  width: 100%;
  margin: 5px 0;
  height: 0.75em;
`;

export const ThinSeparator = styled(Separator)`
  height: 0.1em;
`;

const ActionsContainer = styled(FlexRow)`
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: white;
  z-index: 450;
  box-shadow: 0 0 4px 0 #d2d9e5;
  padding: 8px 16px;
`;

const StarContainer = styled(FlexRow)`
  align-self: center;
`;
