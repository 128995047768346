import React from "react";
import styled, {css} from 'styled-components/macro'
import loading from "../assets/loading.svg";

const Loading = ({ fullScreen=true, sizeToFit=false }) => (
  <Spinner fullScreen={fullScreen} sizeToFit={sizeToFit}>
    <img src={loading} alt="Loading" />
  </Spinner>
);

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  ${props => props.fullScreen && css`
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;  
  `}
  ${props => props.sizeToFit && css`
    height: 100%;
  `};
`;

export default Loading;
