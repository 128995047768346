import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import styled from 'styled-components/macro';

import {
  BoldedText,
  FlexRow,
} from "../BaseStyledComponents";
import {useAuth} from "../../contexts/AuthContext";
import {fetchProfessional, fetchProfile} from "../../redux/actions/professional";
import Loading from "../Loading";
import {ProfileView} from "./ProfileView";


export const ProfileViewPage = () => {

  let { uid } = useParams();
  const { loading, isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();

  const professionals = useSelector(state => state.professionals);
  const profile = useSelector(state => state.professionals.profile);
  const professional = professionals.professionals && professionals.professionals[uid]

  useEffect(() => {
    dispatch(fetchProfile())
    dispatch(fetchProfessional(uid))
  }, [])

  if (!professional || loading || professionals.loadingProfile) {
    return <Loading fullScreen={false}/>
  }

  if ((profile.professionalUid !== uid) || !isAuthenticated) {
    history.push("/")
  }


  return (
    <ProfileRowContainer>
      <ProfileView professional={professional} accountProfile={true}/>
    </ProfileRowContainer>
  )
};

const ProfileRowContainer = styled(FlexRow)`
  justify-content: center;
  background-color: white;
;`

export const HeaderText = styled(BoldedText)`
  align-self: flex-start;
  padding-right: 5px;
  font-size: 14px;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.17px;
  font-stretch: normal;
  font-style: normal;
  color: #191919;
`;

const Separator = styled.hr`
  background-color: #efedea;
  border: none;
  width: 100%;
  margin: 5px 0;
  height: 0.75em;
`;

export const ThinSeparator = styled(Separator)`
  height: 0.1em;
`;
