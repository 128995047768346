import fetch from 'cross-fetch'
import {accessToken} from "../../contexts/AuthContext";

const fetchMiddleware = store => next => action => {
  next(action); // ensures the action is evaluated

  // If we are dispatching an action that doesn't make a web request
  if (!action.meta || !action.meta.endpoint ) {
    return
  }

  let options = {};
  // console.log(accessToken);

  options.headers = action.meta.headers || {"Content-Type": "application/json"};
  if (accessToken) {
    options.headers.Authorization = `Bearer ${accessToken}`;
  }
  // options.mode = "same-origin";

  switch (action.meta.method) {
    case "PUT":
    case "POST":
      options.method = action.meta.method.toLowerCase();

      if (action.meta.body) {
        options.headers["Accept"] = "application/json";
        options.body = JSON.stringify(action.meta.body);
      }
      break;
    case "DELETE":
      options.method = "delete";
      if (action.meta.body) {
        options.headers["Accept"] = "application/json";
        options.body = JSON.stringify(action.meta.body);
      }
      break;
    default:
      options.method = 'get'
  }

  let endpoint = action.meta.endpoint.startsWith("http://")
    ? action.meta.endpoint
    : process.env.REACT_APP_API + action.meta.endpoint;

  fetch(endpoint, options)
    .then((response) => {
      if ([200, 201, 204].indexOf(response.status) < 0) {
        let error = new Error();
        error.message = "Didn't receive 200, 201, or 204 status";
        throw error
      }
      return response.json()
    })
    .then((json) => {
      if (action.meta.success) {
        store.dispatch(action.meta.success(json))
      }
    })
    .catch(error => {
      if (action.meta.error) {
        store.dispatch(action.meta.error(error))
      }
    })
};

export default fetchMiddleware