import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import styled, {css} from 'styled-components/macro';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import { geocodeByPlaceId, getLatLng } from "react-places-autocomplete";

import './Search.css';
import {
  fetchProfessionTypes,
  fetchProfessionals,
  createGroup,
  updateGroups,
  fetchMoreProfessionals
} from "../../redux/actions/professional";
import Modal from "react-modal";
import {CreateGroupForm} from "../../components/CreateGroupForm";
import {
  FlexColumn,
  FlexRow,
  BoldedText,
  SearchBarContainer
} from "../../components/BaseStyledComponents";
import {fetchPlaces} from "../../utils/api";
import SearchResults, {StyledRemoveScroll} from "./SearchResults";
import Loading from "../../components/Loading";
import {selectEvent} from "../../utils/amplitude";
import {AuthenticationForm} from "../../components/AuthenticationForm";
import {useAuth} from "../../contexts/AuthContext";
import {LocationIcon} from "../../components/Profile/ProfileView";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RightArrowIcon} from "../../components/ProfessionalListItem";
import {billingOptions, distanceOptions, feeAmountOptions, waitTimeOptions} from "../../utils/constants";

Modal.setAppElement('#root');


const createGroupCustomStyles = {
  content : {
    top                   : '40%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    height                : '305px',
    width                 : '400px',
    transform             : 'translate(-50%, -50%)'
  }
};

const deleteGroupCustomStyles = {
  content : {
    top                   : '40%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    height                : '250px',
    width                 : '400px',
    transform             : 'translate(-50%, -50%)'
  }
};


const customStyles = {
  container: (provided, _) => ({
    ...provided,
    width: "100%",
  }),
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: 'papayawhip'
    }
  }
};

const filtersCustomStyles = {
  menuPortal: base => ({
    ...base, zIndex: 9999
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.hasValue ? "#28a745b5" : provided.backgroundColor,
  }),
  container: (provided, _) => ({
    ...provided,
    width: "100%",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.hasValue ? "white" : provided.color,
    fontWeight: state.hasValue ? "900" : provided.fontWeight
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    color: state.hasValue ? "white" : provided.color,
  }),
  menuList: (provided, state) => ({
    ...provided,
    fontFamily: "Lato,Helvetica,Arial,serif",
    fontSize: "14px"
  })
};


const formatProfessionTypes = (professionTypes) => {
  return professionTypes.map((professionType) => {
    return ({
        "value": professionType.uid,
        "label": professionType.name,
      })
  });
};

const getInitiatedProfessions = (professionTypes) => {
  if (professionTypes && professionTypes.length > 0) {
    return professionTypes.filter(professionType => {
      return ["Clinical Psychologist", "Psychologist"].indexOf(professionType.label) >= 0
    })
  } else {
    return []
  }
}

const Search = () => {
  // Redux state initialisation
  const professionals = useSelector(state => state.professionals.professionals);
  const loadingProfessionals = useSelector(state => state.professionals.loadingProfessionals);
  const loadingMoreProfessionals = useSelector(state => state.professionals.loadingMoreProfessionals);
  const professionTypes = useSelector(state => state.professionals.professionTypes);
  const searchOffset = useSelector(state => state.professionals.offset);

  const dispatch = useDispatch();


  const [createGroupModalOpen, setCreateGroupModalOpen] = useState(false);
  // const [removeFromGroupModalOpen, setRemoveFromGroupModalOpen] = useState(false);
  const [deleteGroupModalOpen, setDeleteGroupModalOpen] = useState(false);
  const [createFormName, setCreateFormName] = useState("");
  const [createFormDescription, setCreateFromDescription] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [professionTextValue, setProfessionTextValue] = useState("");
  // const [locationSearchTerm, setLocationSearchTerm] = useState("");
  const [locationSelected, setLocationSelected] = useState(null);
  const [latLng, setLatLng] = useState("");
  const [distance, setDistance] = useState("");
  const [billingType, setBillingType] = useState(null);
  const [maxWaitTime, setMaxWaitTime] = useState(null);
  const [maxFeeAmount, setMaxFeeAmount] = useState(null);
  const [selectedProfessionals, setSelectedProfessionals] = useState(new Set());
  const [favouritesToggle, setFavouritesToggle] = useState(false);
  const [professionTypesSelections, setProfessionTypesSelections] = useState([]);
  const [selectedProfessionTypes, setSelectedProfessionTypes] = useState([]);
  const [authMode, setAuthMode] = useState("login");
  const [authModalOpen, setAuthModalOpen] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");

  const { isAuthenticated } = useAuth()

  const filterScrollRef = useRef()
  const observer = useRef()
  const lastProfessionalRef = useCallback(element => {
    if (loadingProfessionals) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && searchOffset) {
        handleSearchProfessionals(false)
      }
    })
    if (element) observer.current.observe(element)
  }, [loadingProfessionals, searchOffset])

  let creatableSelectRef


  const authModalCustomStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      height                : '95%',
      maxHeight             : authMode === "signup"
                                ? '470px'
                                : (authMode === "login"
                                  ? '405px'
                                  : '335px'),
      width                 : '100%',
      maxWidth              : '350px',
      transform             : 'translate(-50%, -50%)',
      padding               : '10px 2px',
    },
    overlay : {
      zIndex                : 500,
      backgroundColor       : "rgb(27 20 20 / 75%)",
    },
  };

  const handleInputChange = (inputValue, { action }) => {
    switch (action) {
      case 'input-change':
        setProfessionTextValue(inputValue);
        return;
      case 'menu-close':
        setProfessionTextValue("");
        return;
      default:
        return;
    }
  };

  const handleLocationSelect = async (action, location) => {
    if (action.action === 'clear') {
      setLatLng("")
      setLocationSelected(false)
      return
    }
    const results = await geocodeByPlaceId(location.value);
    const latLng = await getLatLng(results[0]);
    // const latBounds = await Object.values(results[0].geometry.bounds.Ya);
    // const lngBounds = await Object.values(results[0].geometry.bounds.Ua);
    setLatLng(`${latLng.lat},${latLng.lng}`)
    setLocationSelected(location)
    handleSearchEvent("location", location)
    if (!distance.length) {
      setDistance(Object.values(distanceOptions)[1])
    }
  };

  const handleSelectedProfessional = (uid) => {
    if (selectedProfessionals.has(uid)) {
      setSelectedProfessionals(selectedProfessionals => new Set([...selectedProfessionals].filter(x => x !== uid)));
    } else {
      setSelectedProfessionals(selectedProfessionals => new Set([...selectedProfessionals, ...new Set([uid])]))
    }

  };

  const handleCreateGroupModalClose = () => {
    setCreateGroupModalOpen(false);
    setCreateFromDescription("");
    setCreateFormName("");
  };

  const handleCreateGroupSubmit = () => {
    dispatch(createGroup(createFormName, createFormDescription));
    setCreateGroupModalOpen(false);
    setCreateFromDescription("");
    setCreateFormName("");
  };

  const handleSearchEvent = (searchField, newValue) => {
    let options = {
      "profession-types": selectedProfessionTypes.map(profType => {return profType["label"]}),
      "keyword": searchTerm && searchTerm.value,
      "location": locationSelected && locationSelected.label,
      "distance": distance && distance.value,
      "billing-type": billingType && billingType.label,
      "wait-time": maxWaitTime && maxWaitTime.label,
      "fee": maxFeeAmount && maxFeeAmount.label,
    }

    switch (searchField) {
      case "profession-types":
        options["profession-types"] = newValue.map(profType => {return profType["label"]})
        break
      case "keyword":
        options["keyword"] = newValue && newValue.value
        break
      case "location":
        options["location"] = newValue && newValue.label
        break
      case "distance":
        options["distance"] = newValue && newValue.value
        break
      case "billing-type":
        options["billing-type"] = newValue && newValue.label
        break
      case "wait-time":
        options["wait-time"] = newValue && newValue.label
        break
      case "fee":
        options["fee"] = newValue && newValue.label
        break
      case "search-scroll":
        break
      default:
        searchField = "unknown"
    }

    selectEvent(searchField, options)
  }

  const handleProfessionTypeSelect = (selections) => {
    setSelectedProfessionTypes(selections);
    if (selections) {
      handleSearchEvent("profession-types", selections)
    }
  };

  const handleSearchProfessionals = (newSearch=true) => {
    if (newSearch) {
      dispatch(fetchProfessionals(
        searchTerm && searchTerm.value,
        selectedProfessionTypes,
        latLng,
        distance && distance.value,
        billingType && billingType.value,
        maxWaitTime && maxWaitTime.value,
        maxFeeAmount && maxFeeAmount.value,
      ))
    } else {
      handleSearchEvent("search-scroll")
      dispatch(fetchMoreProfessionals(
        searchTerm && searchTerm.value,
        selectedProfessionTypes,
        latLng,
        distance && distance.value,
        billingType && billingType.value,
        maxWaitTime && maxWaitTime.value,
        maxFeeAmount && maxFeeAmount.value,
        searchOffset
      ))
    }
  }

  const handleKeywordFocus = () => {
    if (searchTerm) {
      creatableSelectRef.state.inputValue = searchTerm.value
      creatableSelectRef.state.value = searchTerm
    }
  };

  const handleKeywordChange = (newValue, _) => {
    setSearchTerm(newValue);
    if (newValue && newValue.value.length > 0) {
      handleSearchEvent("keyword", newValue)
    }
  };

  const handleDistanceChange = (newDistance, _) => {
    setDistance(newDistance);
    if (newDistance && newDistance.value.length > 0) {
      handleSearchEvent("distance", newDistance)
    }
  };

  const handleWaitTimeChange = (newWaitTime, _) => {
    setMaxWaitTime(newWaitTime);
    if (newWaitTime && newWaitTime.value) {
      handleSearchEvent("wait-time", newWaitTime)
    }
  };

  const handleFeeChange = (newFee, _) => {
    setMaxFeeAmount(newFee);
    if (newFee && newFee.value) {
      handleSearchEvent("fee", newFee)
    }
  };

  const handleBillingTypeChange = (newBillingType, _) => {
    setBillingType(newBillingType);
    if (newBillingType && newBillingType.value.length > 0) {
      handleSearchEvent("billing-type", newBillingType)
    }
  };

  const handleKeywordMenuClose = () => {
    creatableSelectRef.select.blur()
  }

  const scrollFilterBar = (scrollAmount) => {
    filterScrollRef.current.scrollLeft += scrollAmount
  }

  useEffect(() => {
    if (!professionTypes) {
      dispatch(fetchProfessionTypes());
    }
  }, [professionTypesSelections]);

  // useEffect(() => {
  //   if (Object.entries(groups).length === 0) {
  //     dispatch(fetchGroups());
  //   }
  // }, []);


  useEffect(() => {
    handleSearchProfessionals()
  }, [selectedProfessionTypes, searchTerm, latLng, distance, billingType, maxFeeAmount, maxWaitTime]);

  useEffect(() => {
    if (!isAuthenticated) {
      handleSearchProfessionals()
      dispatch(updateGroups({}))
    } else {
      handleSearchProfessionals()
    }
  }, [isAuthenticated]);

  if (!professionTypesSelections.length && professionTypes) {
    let formattedProfessionTypes = formatProfessionTypes(professionTypes);
    setProfessionTypesSelections(formattedProfessionTypes);
    setSelectedProfessionTypes(getInitiatedProfessions(formattedProfessionTypes))
  }

  // let atFilterScrollStart = filterScrollRef.current && filterScrollRef.current.scrollLeft === 0;
  // let atFilterScrollEnd = filterScrollRef.current && (filterScrollRef.current.scrollLeft === filterScrollRef.current.scrollWidth);

  if (professionTypesSelections.length > 0) {
    return (
      <div className="homepage" id="search-container-portal">
        <div className="professional-body">
          <AllSearchFiltersContainer>
            <SearchContainer>
              <SearchKeyword>
                <SearchBarContainer>
                  <CreatableSelect
                    components={{
                      DropdownIndicator:() => null,
                      IndicatorSeparator:() => null,
                      Option: ({ children, label, data, ...props }) => {
                        return (
                          <components.Option {...props}>
                            {`Search "${data.value}"`}
                          </components.Option>
                        );
                      },
                      SingleValue: ({ children, ...props }) => {
                        return (
                          <components.SingleValue {...props}>
                            <FlexRow>
                              <SearchIcon icon={["fas", "search"]}/>
                              {children}
                            </FlexRow>
                          </components.SingleValue>
                        );
                      },
                      Placeholder: ({ children, ...props }) => {
                        return (
                          <components.Placeholder {...props}>
                            <FlexRow>
                              <SearchIcon icon={["fas", "search"]}/>
                              {children}
                            </FlexRow>
                          </components.Placeholder>
                        );
                      },
                    }}
                    styles={customStyles}
                    isSearchable
                    isClearable
                    onFocus={handleKeywordFocus}
                    onChange={handleKeywordChange}
                    onMenuClose={handleKeywordMenuClose}
                    blurInputOnSelect={true}
                    value={searchTerm}
                    noOptionsMessage={() => {return null}}
                    placeholder="Search by keyword..."
                    ref={(ref) => {creatableSelectRef = ref}}
                  />
                  {/*<KeywordSearchInput*/}
                  {/*  minLength={3}*/}
                  {/*  debounceTimeout={500}*/}
                  {/*  onChange={(e) => handleSearchTermChange(e.target.value)}*/}
                  {/*  placeholder="Search by name, specialty, or clinic..."*/}
                  {/*/>*/}
                </SearchBarContainer>
              </SearchKeyword>
              <SearchFieldContainer>
                <ProfessionTypeDropdown>
                  <Select
                    components={{
                      Placeholder: ({ children, ...props }) => {
                        return (
                          <components.Placeholder {...props}>
                            <SearchIcon icon={["fas", "users"]}/>
                            Search professions...
                          </components.Placeholder>
                        );
                      },
                      MultiValue: ({ children, ...props }) => {
                        return (
                          <components.MultiValue {...props}>
                            <SearchIcon icon={["fas", "users"]}/>
                            {children}
                          </components.MultiValue>
                        );
                      }
                    }}
                    options={professionTypesSelections}
                    value={selectedProfessionTypes}
                    inputValue={professionTextValue}
                    onChange={handleProfessionTypeSelect}
                    onInputChange={handleInputChange}
                    styles={customStyles}
                    closeMenuOnSelect={false}
                    isMulti
                    isSearchable
                    name="profession"
                    placeholder="Select professions..."
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </ProfessionTypeDropdown>
              </SearchFieldContainer>
              <SearchLocation>
                <LocationSearchBarContainer>
                  <AsyncSelect
                    onChange={(location, action) => handleLocationSelect(action, location)}
                    loadOptions={fetchPlaces}
                    styles={customStyles}
                    components={{
                      DropdownIndicator:() => null,
                      IndicatorSeparator:() => null,
                      SingleValue: ({ children, ...props }) => {
                        return (
                          <components.SingleValue {...props}>
                            <FlexRow>
                              <LocationIcon icon={["fas", "map-marker-alt"]}/>
                              {children}
                            </FlexRow>
                          </components.SingleValue>
                        );
                      },
                      Placeholder: ({ children, ...props }) => {
                        return (
                          <components.Placeholder {...props}>
                            <FlexRow>
                              <LocationIcon icon={["fas", "map-marker-alt"]}/>
                              {children}
                            </FlexRow>
                          </components.Placeholder>
                        );
                      },
                    }}
                    noOptionsMessage={(input) => {return (input.inputValue.length > 0 ? "No Options" : null)}}
                    isSearchable
                    isClearable
                    name="location"
                    placeholder="Add a location..."
                  />
                </LocationSearchBarContainer>
                <DistanceContainer hide={!locationSelected}>
                  <Select
                    options={distanceOptions}
                    value={distance}
                    onChange={handleDistanceChange}
                    placeholder="Distance..."
                    style={customStyles}
                    components={{
                      SingleValue: ({ children, ...props }) => {
                        return (
                          <components.SingleValue {...props}>
                            {"within " + children}
                          </components.SingleValue>
                        );
                      },
                      Placeholder: ({ children, ...props }) => {
                        return (
                          <components.Placeholder {...props}>
                            {"within " + children}
                          </components.Placeholder>
                        );
                      },
                      IndicatorSeparator: () => null,
                    }}
                  />
                </DistanceContainer>
              </SearchLocation>
            </SearchContainer>
            {/*{selectedProfessionTypes.length > 0 && selectedProfessionTypes.filter(profession => {*/}
            {/*  return ["392e49f648774e29a2453957a799cc6e", "0979ebb822b64f7fa518a3c8624bf6bb"].indexOf(profession.value) >= 0*/}
            {/*}).length > 0 &&*/}
            <FiltersNoteText>These filters are only available for psychologists</FiltersNoteText>
            <FiltersContainer>
              {/*<MoreFiltersIcon hide={atFilterScrollStart} icon={["fas", "angle-left"]} onClick={() => scrollFilterBar(-80)}/>*/}
              <FiltersScrollContainer ref={filterScrollRef}>
                <FiltersText>Filters</FiltersText>
                <WaitTimeContainer>
                  <Select
                    options={waitTimeOptions}
                    value={maxWaitTime}
                    onChange={handleWaitTimeChange}
                    placeholder="Wait Time"
                    styles={filtersCustomStyles}
                    isSearchable={false}
                    isClearable
                    menuPortalTarget={document.body}
                    components={{
                      DropdownIndicator: ({...props}) =>
                        props.hasValue ? null : <components.DropdownIndicator {...props}/>,
                      IndicatorSeparator: () => null
                    }}
                  />
                </WaitTimeContainer>
                <FeesContainer>
                  <Select
                    options={feeAmountOptions}
                    value={maxFeeAmount}
                    onChange={handleFeeChange}
                    placeholder="Standard Fee (~50min)"
                    styles={filtersCustomStyles}
                    isSearchable={false}
                    isClearable
                    menuPortalTarget={document.body}
                    components={{
                      DropdownIndicator: ({...props}) =>
                        props.hasValue ? null : <components.DropdownIndicator {...props}/>,
                      IndicatorSeparator: () => null
                    }}
                  />
                </FeesContainer>
                <BillingContainer>
                  <Select
                    options={billingOptions}
                    value={billingType}
                    onChange={handleBillingTypeChange}
                    placeholder="Billing"
                    isSearchable={false}
                    isClearable
                    menuPortalTarget={document.body}
                    styles={filtersCustomStyles}
                    components={{
                      DropdownIndicator: ({...props}) =>
                        props.hasValue ? null : <components.DropdownIndicator {...props}/>,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </BillingContainer>
              </FiltersScrollContainer>
              {/*{filterScrollRef.scrollLeft === (filterScrollRef.scrollWidth - filterScrollRef.offsetWidth)*/}
              {/*&& <MoreFiltersIcon icon={["fas", "angle-right"]} onClick={() => scrollFilterBar(80)}/>*/}
              {/*}*/}
              <MoreFiltersIcon icon={["fas", "angle-right"]} onClick={() => scrollFilterBar(80)}/>
            </FiltersContainer>
            {/*}*/}
          </AllSearchFiltersContainer>
          <SearchResults
            favouritesToggle={favouritesToggle}
            setFavouritesToggle={setFavouritesToggle}
            filteredProfessionals={Object.values(professionals)}
            selectedProfessionals={selectedProfessionals}
            setSelectedProfessionals={setSelectedProfessionals}
            handleSelectedProfessional={handleSelectedProfessional}
            loadingProfessionals={loadingProfessionals}
            loadingMoreProfessionals={loadingMoreProfessionals}
            setAuthModalOpen={setAuthModalOpen}
            lastProfessionalRef={lastProfessionalRef}
          />
        </div>
        {/*BELOW WE KEEP THE CODE FOR THE MODALS*/}
        <Modal
          isOpen={createGroupModalOpen}
          onRequestClose={() => setCreateGroupModalOpen(!createGroupModalOpen)}
          style={createGroupCustomStyles}
          contentLabel="Example Modal"
        >
          <CreateGroupForm
            groupName={createFormName}
            groupDescription={createFormDescription}
            setGroupName={setCreateFormName}
            setGroupDescription={setCreateFromDescription}
            handleSubmit={handleCreateGroupSubmit}
            handleClose={handleCreateGroupModalClose}
          />
        </Modal>
        <Modal
          isOpen={authModalOpen}
          onRequestClose={() => setAuthModalOpen(false)}
          style={authModalCustomStyles}
          contentLabel="Example Modal"
        >
          <StyledRemoveScroll>
            <AuthenticationForm setAuthMode={setAuthMode} authMode={authMode} handleCloseModal={() => setAuthModalOpen(false)}/>
          </StyledRemoveScroll>
        </Modal>
        <Modal
          isOpen={deleteGroupModalOpen}
          onRequestClose={() => setDeleteGroupModalOpen(!deleteGroupModalOpen)}
          style={deleteGroupCustomStyles}
          contentLabel="Example Modal"
        >
          {/*<DeleteGroupConfirmation*/}
            {/*group={group}*/}
            {/*handleSubmit={() => handleDeleteGroupConfirm(group)}*/}
            {/*handleClose={() => setDeleteGroupModalOpen(!deleteGroupModalOpen)}*/}
          {/*/>*/}
        </Modal>
      </div>
    );
  } else {
    return (
      <LoadingContainer>
        <Loading fullScreen={false}/>
      </LoadingContainer>
    )
  }
};

export default Search;

export const LoadingContainer = styled.div`
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
`;

const AllSearchFiltersContainer = styled.div`
  width: 100%;
`;

export const SearchContainer = styled(FlexRow)`
  width: 100%;
  justify-content: center;
  padding: 0 10px;
  flex-wrap: wrap;
`;

const SearchFieldContainer = styled(FlexColumn)`
  padding: 0 10px;
  flex-grow: 1;
  max-width: 350px;
  min-width: 250px
`;

const SearchKeyword = styled(SearchFieldContainer)`
  flex-grow: 2;
`;

const SearchIcon = styled(FontAwesomeIcon)`
  font-size: 15px;
  align-self: center;
  margin-right: 8px;
  color: #0f6fff;
`;

const SearchLocation = styled(SearchFieldContainer)`
`;

const LocationSearchBarContainer = styled(SearchBarContainer)`
  padding: 0;
  color: #6d6d6d;
  font-size: 16px;
`;

const ProfessionTypeDropdown = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  color: #6d6d6d;
  padding: 0 0 25px 0;
  font-size: 16px;
  min-width: 250px;
`;

const DistanceContainer = styled.div`
  width: 140px;
  font-size: 16px;
  color: hsl(0,0%,50%);
  align-self: flex-end;
  padding-top: 5px;
  padding-bottom: 15px;
  ${props => props.hide && css`
    opacity: 0;
    padding-bottom: 0;
  `}
`;

const FiltersContainer = styled(FlexRow)`
  max-width: 100%;
  justify-content: center;
  padding: 0 20px;
  font-size: 14px;
`;

const FiltersScrollContainer = styled(FlexRow)`
  white-space: nowrap;
  overflow-x: auto;
`;

const FiltersText = styled(BoldedText)`
  font-size: 16px;
  align-self: center;
  color: #191919;
  padding-right: 10px;
`;

const FiltersNoteText = styled(BoldedText)`
  justify-content: center;
  font-size: 13px;
`;

const BillingContainer = styled.div`
  min-width: 185px;
  font-size: 14px;
  white-space: nowrap;
  color: hsl(0,0%,50%);
  padding: 5px 2px 5px;
  ${props => props.hide && css`
    opacity: 10;
  `}
`;

const MoreFiltersIcon = styled(RightArrowIcon)`
  font-size: 20px;
  align-self: center;
`;

const WaitTimeContainer = styled.div`
  min-width: 120px;
  white-space: nowrap;
  font-size: 14px;
  color: hsl(0,0%,50%);
  padding: 5px 2px 5px;
  ${props => props.hide && css`
    opacity: 10;
  `}
`;

const FeesContainer = styled.div`
  min-width: 190px;
  white-space: nowrap;
  font-size: 14px;
  color: hsl(0,0%,50%);
  padding: 5px 2px 5px;
  ${props => props.hide && css`
    opacity: 10;
  `}
`;
